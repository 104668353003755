<template>
  <div>
    <v-fab-transition v-if="isModification">
      <v-btn v-hotkey="'ctrl+s'" color="green" fab dark fixed bottom right @click="save">
        <v-icon large>mdi-content-save-outline</v-icon>
      </v-btn>
    </v-fab-transition>
    <v-snackbar v-model="isSaved" color="success">
      La section a été sauvegardée avec succès.

      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="isSaved = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
    <v-form ref="sectionVideos" v-model="valid">
      <v-container>
        <v-row>
          <v-col>
            <v-alert v-if="errorMessage" dense text border="left" type="error" elevation="2">
              {{ errorMessage }}
            </v-alert>
            <v-alert v-if="isModification" dense text border="left" type="warning" elevation="2">
              Attention des modifications semblent en cours dans cet onglet. Veuillez vous assurer de sauvegarder avant
              de changer de section ou d'onglet. (Appuyer sur CTRL+S pour sauvegarder)
            </v-alert>
          </v-col>
        </v-row>
        <v-row class="mb-4">
          <v-col>
            <v-row>
              <v-col>
                <v-switch v-model="section.video_info" label="Activer la section vidéos" class="mb-n6"></v-switch>
                <small class="text--secondary"> (L'activation de la section sera sauvegardée automatiquement)</small>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <div v-for="(video, i) in videos" :key="i" class="text-fields-row">
          <v-text-field
            label="Lien du vidéo"
            v-model="video.link"
            :rules="textFieldRules"
            @keyup="handleChangeVideos"
          ></v-text-field>
          <v-text-field label="Description" v-model="video.description" @keyup="handleChangeVideos"></v-text-field>
          <v-btn @click="removeRow(i)" class="error">X</v-btn>
        </div>
        <v-btn @click="addRow" class="primary">Ajouter un vidéo</v-btn>
        <v-row class="mt-4">
          <v-col>
            <h3>Visualisation</h3>
          </v-col>
        </v-row>
        <v-row>
          <draggable v-model="videos" class="row" @change="handleChangeVideos">
            <v-col v-for="(video, index) in videos" :key="`video-${index}`" cols="4">
              <v-card flat>
                <v-card-text>
                  <video-embed :src="video.link"></video-embed>
                  <p class="caption">{{ video.description }}</p>
                </v-card-text>
              </v-card>
            </v-col>
          </draggable>
        </v-row>
      </v-container>
    </v-form>
  </div>
</template>

<script>
  import draggable from "vuedraggable";
  import { SectionsService } from "@/store/modules/sections";
  export default {
    components: {
      draggable,
    },
    props: {
      section: {
        type: Object,
        default: function() {
          return {};
        },
      },
      reloadData: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        isSaved: false,
        isModification: false,
        errorMessage: "",
        valid: true,
        videos: [],
        textFieldRules: [(value) => !!value || "Required."],
      };
    },
    async created() {
      this.getVideos();
    },
    async updated() {
      if (this.reloadData) {
        this.getVideos();
        this.$emit("update", false);
      }
    },
    methods: {
      addRow() {
        this.videos.push({
          link: "",
          description: "",
        });
        this.handleChangeVideos();
      },
      removeRow(index) {
        this.videos.splice(index, 1);
      },
      async getVideos() {
        const sectionVideos = await SectionsService.actions.findSectionVideos(this.section.id);
        this.videos = sectionVideos ? JSON.parse(sectionVideos.links) : [];
      },
      handleChangeVideos() {
        this.isSaved = false;
        this.errorMessage = "";
        this.isModification = true;
      },
      async save() {
        if (this.valid) {
          SectionsService.actions
            .setSectionVideos(this.section.id, this.videos)
            .then(() => {
              this.isModification = false;
              this.isSaved = true;
              this.successMessage;
            })
            .catch((error) => {
              console.log(error);
              this.errorMessage =
                "Une erreur s'est produite lors de la sauvegarde des vidéos, vérifiez les résultats et recommencez.";
            });
        } else {
          this.errorMessage = "Certains champs obligatoires ne répondent pas aux critères attendus, veuillez vérifier.";
        }
      },
    },
    watch: {
      reloadData(_o, _n) {
        if (this.reloadData) {
          this.isSaved = false;
          this.errorMessage = "";
          this.isModification = false;
        }
      },
      "section.id"(o, n) {
        if (o !== n) {
          this.isModification = false;
        }
      },
      "section.video_info"(_o, _n) {
        SectionsService.actions.updateSection(this.section);
      },
    },
  };
</script>

<style lang="scss" scoped>
  .text-fields-row {
    display: flex;
  }
</style>
