import Vue from "vue";
import VueRouter from "vue-router";
import { auth } from "@/firebase";

import Home from "@/components/pages/Home";
import About from "@/components/pages/About";
import RiverList from "@/components/pages/river/RiverList";
import RiverDetail from "@/components/pages/river/RiverDetail";
import NewUser from "@/components/pages/NewUser";
import Login from "@/components/pages/Login";
import ResetPassword from "@/components/pages/ResetPassword";
import Subscription from "@/components/pages/subscription/Subscription";
import StripePortal from "@/components/pages/subscription/StripePortal";
import InsertData from "@/components/pages/admin/InsertData";
import Dashboard from "@/components/pages/admin/Dashboard";
import AdminGaugeList from "@/components/pages/admin/GaugeList";
import AdminGaugeEdit from "@/components/pages/admin/GaugeEdit";
import AdminSections from "@/components/pages/admin/Sections";
import AdminUserList from "@/components/pages/admin/UserList";
import AdminUserEdit from "@/components/pages/admin/UserEdit";

import { UserService } from "@/store/modules/user";

Vue.use(VueRouter);

const routes = [
  { name: "accueil", path: "/", component: Home },
  { name: "a-propos", path: "/a-propos", component: About },
  { name: "rivieres", path: "/rivieres", component: RiverList },
  { name: "riviere", path: "/riviere/:slug", component: RiverDetail },
  { name: "nouvel-utilisateur", path: "/nouvel-utilisateur", component: NewUser },
  { name: "connexion", path: "/connexion", component: Login },
  { name: "reinitialiser-mot-de-passe", path: "/reinitialiser-mot-de-passe", component: ResetPassword },
  { name: "subscription", path: "/subscription", component: Subscription },
  { name: "payment-portal", path: "/payment-portal", component: StripePortal },
  { name: "insert-data", path: "/admin/insert-data", component: InsertData, meta: { requiresAdmin: true } },
  { name: "admin-dashboard", path: "/admin/dashboard", component: Dashboard, meta: { requiresAdmin: true } },
  { name: "admin-gauge-list", path: "/admin/gauges-list", component: AdminGaugeList, meta: { requiresAdmin: true } },
  {
    name: "admin-gauge-edit",
    path: "/admin/gauges-edit/:slug",
    component: AdminGaugeEdit,
    meta: { requiresAdmin: true },
  },
  {
    name: "admin-gauge-add",
    path: "/admin/gauges-edit/new",
    component: AdminGaugeEdit,
    meta: { requiresAdmin: true },
  },
  { name: "admin-sections", path: "/admin/sections", component: AdminSections, meta: { requiresAdmin: true } },
  { name: "admin-user-list", path: "/admin/user-list", component: AdminUserList, meta: { requiresAdmin: true } },
  { name: "admin-user-edit", path: "/admin/user-edit/:slug", component: AdminUserEdit, meta: { requiresAdmin: true } },
  { name: "admin-user-add", path: "/admin/user-edit/new", component: AdminUserEdit, meta: { requiresAdmin: true } },
];

// eslint-disable-next-line no-new
const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(_to, _from, savedPosition) {
    return new Promise((resolve, _reject) => {
      setTimeout(() => {
        if (savedPosition) {
          resolve(savedPosition);
        } else {
          resolve({ x: 0, y: 0 });
        }
      }, 500);
    });
  },
});

router.beforeEach(async (to, from, next) => {
  auth.onAuthStateChanged(async (user) => {
    var userRoles;
    user ? (userRoles = await UserService.actions.getUserRoles(user.uid)) : null;
    const isProtected = to.matched.some((route) => route.meta.requiresAdmin);
    const isAdmin = userRoles ? userRoles.isAdmin : false;
    //console.log(`IsAdmin ${isAdmin} isProtected ${isProtected}`);
    if (!isAdmin && isProtected) {
      next("/");
    } else {
      next();
    }
  });
});

export default router;
