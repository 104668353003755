<template v-if="subscriptions">
  <v-container>
    <h1>Abonnement</h1>
    <v-card elevation="12">
      <v-card-title>🌊 {{ subscriptions[0].name }}</v-card-title>
      <v-card-text>
        <v-list>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>
                🚣‍♂️ Accès Complet aux Débits en Temps Réel
              </v-list-item-title>
              Explorez des détails précis sur les débits des rivières, vous permettant de planifier vos aventures
              nautiques en toute confiance.
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>
                🌐 Extrapolation Avancée des Données
              </v-list-item-title>
              Profitez de données extrapolées pour les rivières sans sonde hydrique, offrant une vision détaillée même
              là où les informations sont rares.
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>
                📸 Visualisez les Conditions en Direct
              </v-list-item-title>
              Grâce à nos webcams stratégiquement positionnées, ayez un aperçu visuel en temps réel des conditions des
              rapides et des cours d'eau.
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>
                🗺️ Cartes Topographiques et Points Clés
              </v-list-item-title>
              Accédez à des cartes topographiques détaillées, avec des informations cruciales sur les points de mise à
              l'eau, les sorties, et les rapides emblématiques.
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>
                💼 Soutenez la Communauté et la Plateforme
              </v-list-item-title>
              Votre adhésion contribue au maintien de notre plateforme, à l'achat de matériel essentiel, et à la
              continuité des mises à jour régulières.
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>
              Rejoignez-nous aujourd'hui et découvrez une expérience immersive pour les amateurs de sports nautiques! 🌟
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <v-card-actions>
          <v-btn color="primary" @click="subscribe">Je m'abonne</v-btn>
        </v-card-actions>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
  import { auth, db } from "@/firebase";

  export default {
    data() {
      return {
        subscriptions: [],
        isLoading: false,
      };
    },
    created() {
      this.fetchSubscriptionPlans();
    },
    methods: {
      async fetchSubscriptionPlans() {
        const subscriptionsRef = db.collection("stripeSubscriptionPlans");
        const subscriptionsQuery = subscriptionsRef.where("active", "==", true);
        const subscriptionsSnapshot = await subscriptionsQuery.get();

        subscriptionsSnapshot.forEach(async (doc) => {
          const pricesRef = db
            .collection("stripeSubscriptionPlans")
            .doc(doc.id)
            .collection("prices");
          const pricesQuerySnapshot = await pricesRef.get();

          this.subscriptions.push({
            id: doc.id,
            name: doc.data().name,
            description: doc.data().description,
            prices: pricesQuerySnapshot.docs.map((price) => {
              return {
                id: price.id,
                ...price.data(),
              };
            }),
          });
        });
      },
      async subscribe() {
        this.isLoading = true;
        const customerId = auth.currentUser.uid;

        const collectionRef = db
          .collection("stripeCustomers")
          .doc(customerId)
          .collection("checkout_sessions");

        const docRef = await collectionRef.add({
          price: this.subscriptions[0].prices[0].id,
          success_url: window.location.origin,
          cancel_url: window.location.origin,
        });

        docRef.onSnapshot(async (snap) => {
          const { error, sessionId, url } = snap.data();

          if (error) {
            // Show an error to your customer and
            // inspect your Cloud Function logs in the Firebase console.
            alert(`An error occured: ${error.message}`);
          }

          if (sessionId) {
            window.location.assign(url);
          }
        });
      },
    },
  };
</script>

<style></style>
