<template>
  <v-container>
    <div v-if="subscription">
      <div v-if="subscription.cancel_at_period_end">
        Date de fin de l'abonnement :
        <p>
          <strong>{{
            new Date(subscription.current_period_end.seconds * 1000).toLocaleDateString("fr-CA", {
              day: "2-digit",
              month: "2-digit",
              year: "numeric",
            })
          }}</strong>
        </p>
      </div>
      <div v-else>
        Renouvellement automatique le :
        <p>
          <strong>
            {{
              new Date(subscription.current_period_end.seconds * 1000).toLocaleDateString("fr-CA", {
                day: "2-digit",
                month: "2-digit",
                year: "numeric",
              })
            }}
          </strong>
        </p>
      </div>
      <v-btn color="primary" :disabled="isLoading" @click="openCustomerPortal">{{
        isLoading ? "Chargement en cours..." : "Ouvrir mon portail d'abonnement"
      }}</v-btn>
    </div>
    <div v-else>
      <div v-if="!isMember">
        <v-banner single-line>
          <v-icon slot="icon" color="warning" size="24">
            mdi-wallet-membership
          </v-icon>
          Vous n'avez actuellement aucun abonnement actif
          <template v-slot:actions>
            <v-btn color="primary" text :to="{ name: 'subscription' }">Devenir membre</v-btn>
          </template>
        </v-banner>
      </div>
      <div v-if="membershipEndDate">
        Votre abonnement est actif jusqu'au :
        <p>
          <strong>
            {{
              new Date(membershipEndDate * 1000).toLocaleDateString("fr-CA", {
                day: "2-digit",
                month: "2-digit",
                year: "numeric",
              })
            }}
          </strong>
        </p>
      </div>
    </div>
    <v-alert v-if="isAdmin" text prominent type="error" icon="mdi-security">
      Vous êtes connecté en tant qu'administrateur
    </v-alert>
  </v-container>
</template>

<script>
  import { app } from "@/firebase";

  export default {
    props: {
      subscription: null,
      isMember: Boolean,
      membershipEndDate: Number,
      isAdmin: Boolean,
    },
    data() {
      return {
        isLoading: false,
      };
    },
    mounted() {},
    methods: {
      async openCustomerPortal() {
        this.isLoading = true;
        const functions = app.functions("northamerica-northeast1");
        const functionRef = functions.httpsCallable("ext-firestore-stripe-payments-createPortalLink");
        const { data } = await functionRef({ return_url: window.location.origin, locale: "fr" });
        window.location.assign(data.url);
        this.isLoading = false;
      },
    },
  };
</script>

<style></style>
