<template>
  <div>
    <v-toolbar color="primary darken-1" dark>
      <v-icon class="mr-8" @click="$router.push({ name: 'admin-user-list' })" title="Retour à la liste des utilisateurs"
        >mdi-arrow-left</v-icon
      >
      <v-toolbar-title class="subtitle-1" v-if="user.uid">
        Modifier un utilisateur <small>(Administration)</small>
      </v-toolbar-title>
      <v-toolbar-title class="subtitle-1" v-else>
        Ajouter un utilisateur <small>(Administration)</small>
      </v-toolbar-title>
    </v-toolbar>
    <v-form>
      <v-container>
        <h1>Information sur l'utilisateur</h1>
        <v-row class="mb-3" no-gutters>
          <v-col md="6">
            <v-text-field v-model="user.displayName" label="Nom" required></v-text-field>
            <div v-if="user.uid">
              <p>{{ user.email }}</p>
              <p>{{ user.uid }}</p>
            </div>
            <div v-else>
              <v-text-field v-model="user.email" label="Email" required></v-text-field>
            </div>
            <v-switch v-model="user.disabled" label="Désactiver" color="red darken-3"></v-switch>
            <v-btn depressed color="primary" @click="saveUserInfo(user)" v-if="user.uid">
              <v-icon>mdi-account-edit</v-icon>
              &nbsp; Modifier les informations utilisateur
            </v-btn>
            <v-btn depressed color="primary" @click="addUserInfo(user)" v-else>
              <v-icon>mdi-account-plus</v-icon>
              &nbsp; Ajouter l'utilisateur
            </v-btn>
          </v-col>
          <v-col md="6"> </v-col>
        </v-row>
        <br />
        <v-divider></v-divider>
        <br />
        <div v-if="user.uid">
          <h1>Information sur l'abonnement</h1>
          <v-row class="mb-3" no-gutters>
            <v-col md="6">
              <h4>Type de membre</h4>
              <v-select v-model="userMembership.access" :items="membershipType"> </v-select>
              <div v-if="userMembership.access === 'member'">
                <h4>Date de fin de l'abonnement</h4>
                <v-date-picker v-model="membershipEndDate"></v-date-picker>
                <h4>Note</h4>
                <v-textarea
                  clearable
                  clear-icon="mdi-close-circle"
                  label="Raison si modification manuelle de l'abonnement"
                  v-model="userMembership.note"
                ></v-textarea>
              </div>
              <v-btn depressed color="primary" @click="saveMembershipInfo(userMembership)">
                <v-icon>mdi-currency-usd</v-icon>
                Modifier les informations sur l'abonnement
              </v-btn>
            </v-col>
            <v-col md="6"> </v-col>
          </v-row>
        </div>
      </v-container>
    </v-form>
  </div>
</template>

<script>
  import { /*auth,*/ functions } from "@/firebase";
  export default {
    data() {
      return {
        user: { uid: null, displayName: "", email: "" },
        userMembership: { id: null, access: "aucun", endDate: null, note: "" },
        membershipEndDate: "",
        membershipType: ["aucun", "member", "admin"],
      };
    },
    watch: {
      async $route() {
        await this.loadUserInfo();
      },
    },
    async mounted() {
      await this.loadUserInfo();
    },
    methods: {
      async loadUserInfo() {
        var path = window.location.pathname;
        var uid = path.replace("/admin/user-edit/", "");
        if (uid != "new") {
          await this.getUserInfo(uid);
          await this.getUserMembership(uid);
          this.getDate(this.userMembership.endDate);
        }
      },
      async getUserInfo(uid) {
        var getUserByUid = functions.httpsCallable("getUserByUid");
        const user = await getUserByUid({ uid: uid })
          .then((result) => {
            return result;
          })
          .catch((error) => {
            return error;
          });
        this.user = user.data;
      },
      async addUserInfo(user) {
        const userInfo = user;
        const updateUserInfo = functions.httpsCallable("updateUserInfo");
        const saveUserInfo = await updateUserInfo({ userData: userInfo })
          .then((result) => {
            return result;
          })
          .catch((error) => {
            return error;
          });

        var slug = saveUserInfo.data.uid;
        this.$router.push({ name: "admin-user-edit", params: { slug: slug, user: saveUserInfo.data } });
      },
      async saveUserInfo(user) {
        const userInfo = user;
        const updateUserInfo = functions.httpsCallable("updateUserInfo");
        const saveUserInfo = await updateUserInfo({ userData: userInfo })
          .then((result) => {
            return result;
          })
          .catch((error) => {
            return error;
          });
        return saveUserInfo;
      },
      async getUserMembership(uid) {
        const getUserMembershipByUid = functions.httpsCallable("getUserMembershipByUid");
        const userMembership = await getUserMembershipByUid({ uid: uid })
          .then((result) => {
            return result;
          })
          .catch((error) => {
            return error;
          });

        if (userMembership.data) {
          this.userMembership = userMembership.data;
        } else {
          this.userMembership = { id: this.user.uid, access: "aucun", endDate: null, note: "" };
        }
      },
      async saveMembershipInfo(userMembershipInfo) {
        if (userMembershipInfo.access == "member") {
          userMembershipInfo.endDate = this.setDate(this.membershipEndDate);
        }
        const setUserMembership = functions.httpsCallable("setUserMembership");
        const userMembership = await setUserMembership({ userRole: userMembershipInfo })
          .then((result) => {
            return result;
          })
          .then((error) => {
            return error;
          });
        return userMembership;
      },
      getDate(unixDate) {
        // Convert timestamp to milliseconds
        if (unixDate) {
          var date = new Date(unixDate * 1000);

          var year = date.getFullYear();
          var month = date.getMonth() + 1;
          var day = date.getDate();
          var zero = month < 10 ? "0" : "";

          var convdataTime = year + "-" + zero + month + "-" + day;

          this.membershipEndDate = convdataTime;
        }
      },
      setDate(date) {
        var unixDate = 0;
        if (date) {
          unixDate = Math.floor(new Date(date).getTime() / 1000);
        }
        return unixDate;
      },
    },
  };
</script>

<style lang="scss" scoped></style>
