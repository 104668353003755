<template>
  <div>
    <v-toolbar color="primary darken-1" dark>
      <v-toolbar-title class="subtitle-1">
        Réintialisation de mot de passe
      </v-toolbar-title>
    </v-toolbar>
    <v-form ref="form" v-model="valid" @submit="resetPassword">
      <v-alert border="left" color="red lighten-1" class="contenu" dark v-if="errorMessage">
        {{ errorMessage }}
      </v-alert>
      <v-alert border="left" color="green lighten-1" class="contenu" dark v-if="successMessage">
        {{ successMessage }} Retour à la <v-btn text to="/connexion"><v-icon>mdi-login</v-icon> connexion</v-btn>
      </v-alert>
      <p class="contenu ml-3">
        Veuillez inscrire votre courriel ici. Si votre adresse courriel existe dans nos systèmes un courriel vous sera
        envoyé.
      </p>
      <v-col cols="12" sm="6">
        <v-text-field v-model="email" :rules="emailRules" label="E-mail" required></v-text-field>
        <v-btn :disabled="!valid" color="primary" class="mr-4" type="submit">
          Réinitialiser le mot de passe
        </v-btn>
      </v-col>
    </v-form>
  </div>
</template>

<script>
  import { auth } from "@/firebase";
  export default {
    data() {
      return {
        valid: true,
        email: "",
        emailRules: [
          (v) => !!v || "Un courriel est requis",
          (v) => /.+@.+\..+/.test(v) || "Le courriel doit être valide",
        ],
        errorMessage: "",
        successMessage: "",
      };
    },
    methods: {
      async resetPassword(evt) {
        evt.preventDefault();

        try {
          await auth.sendPasswordResetEmail(this.email);
          this.successMessage = "Courriel envoyé avec succès.";
          this.errorMessage = "";
        } catch (error) {
          this.errorMessage = "L'adresse courriel n'existe pas dans nos systèmes.";
          this.successMessage = "";
        }
      },
    },
  };
</script>

<style lang="scss" scoped></style>
