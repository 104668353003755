<template>
  <div>
    <v-fab-transition v-if="isModification">
      <v-btn v-hotkey="'ctrl+s'" color="green" fab dark fixed bottom right @click="save">
        <v-icon large>mdi-content-save-outline</v-icon>
      </v-btn>
    </v-fab-transition>
    <v-snackbar v-model="isSaved" color="success">
      La section a été sauvegardée avec succès.

      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="isSaved = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
    <v-container>
      <v-row>
        <v-col>
          <v-alert v-if="errorMessage" dense text border="left" type="error" elevation="2">
            {{ errorMessage }}
          </v-alert>
          <v-alert v-if="isModification" dense text border="left" type="warning" elevation="2">
            Attention des modifications semblent en cours dans cet onglet. Veuillez vous assurer de sauvegarder avant de
            changer de section ou d'onglet. (Appuyer sur CTRL+S pour sauvegarder)
          </v-alert>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-row>
            <v-col>
              <v-switch v-model="section.topo_info" label="Activer la section Topographie" class="mb-n6"></v-switch>
              <small class="text--secondary"> (L'activation de la section sera sauvegardée automatiquement)</small>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-form ref="sectionTopos" v-model="valid">
        <v-row>
          <v-col>
            <v-radio-group v-model="topo.type" @change="updateTopoType($event)" row>
              <v-radio label="Lien externe" value="external"></v-radio>
              <v-radio label="Rapides" value="rapids"></v-radio>
            </v-radio-group>
          </v-col>
        </v-row>
        <v-row v-if="topo.type == 'external'">
          <v-col>
            <v-text-field label="Lien HTML" v-model="topo.HrefLink" clearable @keyup="handleChange()"></v-text-field>
            <v-container>
              <v-img max-height="600" max-width="600" :src="topo.HrefLink"></v-img>
            </v-container>
          </v-col>
        </v-row>
        <v-row v-if="topo.type == 'rapids'">
          <v-col>
            <v-dialog v-model="dialogImport" width="500">
              <v-card>
                <v-card-title class="headline grey lighten-2">
                  Importer une photo
                </v-card-title>

                <v-card-text class="mt-6">
                  <FileDrop :files="photosDropzone" accept=".jpg,.JPG" label></FileDrop>
                </v-card-text>

                <v-card-text v-if="photosDropzone.length !== 0" class="d-flex justify-center">
                  <v-btn class="primary" @click="uploadPhotos()" :disabled="uploadWaiting" :loading="uploadWaiting">
                    <v-icon left dark>mdi-cloud-upload</v-icon>
                    Télécharger l'image
                  </v-btn>
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="primary" text @click="dialogImport = false">
                    Annuler l'action
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <draggable v-model="rapids">
              <v-row v-for="(rapid, i) in rapids" :key="`rapid-${i}`">
                <v-col>
                  <v-hover v-slot="{ hover }">
                    <v-sheet color="blue lighten-5" class="mb-6 p-6" :elevation="hover ? 12 : 6">
                      <v-row>
                        <v-col class="ml-3">
                          <div class="d-inline-block">
                            <span class="text-h5 font-weight-bold"> Rapide {{ i + 1 }} - </span>
                          </div>
                          <div class="d-inline-block ml-2">
                            <v-text-field
                              label="Nom"
                              v-model="rapid.name"
                              clearable
                              @keyup="handleChange()"
                            ></v-text-field>
                          </div>
                          <div class="d-inline-block text-right" style="float: right;">
                            <v-btn color="error" class="mr-3" fab x-small @click="deleteRapid(i)">
                              <v-icon>mdi-delete</v-icon>
                            </v-btn>
                          </div>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col class="ml-3">
                          <v-autocomplete
                            v-model="rapid.class"
                            :items="rapidClassifications"
                            label="Difficulté"
                            @change="handleChange()"
                          ></v-autocomplete>
                          <v-btn color="primary" class="mt-13 mb-n7" fab bottom x-small @click="addRapid(i + 1)">
                            <v-icon>mdi-plus</v-icon>
                          </v-btn>
                        </v-col>
                        <v-col>
                          <v-text-field
                            label="Gradient"
                            v-model="rapid.gradient"
                            clearable
                            @keyup="handleChange()"
                          ></v-text-field>
                        </v-col>
                        <v-col align="right">
                          <div v-if="rapid.image.length > 0">
                            <v-avatar class="ma-3" size="175" tile>
                              <v-img :src="rapid.image"></v-img>
                            </v-avatar>
                            <v-btn color="error" class="ml-n8" icon>
                              <v-icon>mdi-delete</v-icon>
                            </v-btn>
                          </div>
                          <div v-else>
                            <v-btn class="primary mr-2" @click="addPhoto(i)">
                              <v-icon class="mr-1">mdi-image</v-icon> Ajouter une photo
                            </v-btn>
                          </div>
                        </v-col>
                      </v-row>
                    </v-sheet>
                  </v-hover>
                </v-col>
              </v-row>
            </draggable>
          </v-col>
        </v-row>
      </v-form>
    </v-container>
  </div>
</template>

<script>
  import FileDrop from "@/components/FileDrop";
  import { UploadService } from "@/store/modules/upload";
  import { SectionsService } from "@/store/modules/sections";
  import draggable from "vuedraggable";
  export default {
    components: {
      FileDrop,
      draggable,
    },
    props: {
      section: {
        type: Object,
        default: function() {
          return {};
        },
      },
      reloadData: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        topo: {},
        dialogImport: false,
        rapidIndex: null,
        rapidClassifications: ["I", "II", "III", "IV", "V", "VI"],
        uploadWaiting: false,
        photosDropzone: [],
        emptyRapid: {
          name: "",
          class: "",
          distance: 0,
          gradient: 0,
          position: { lat: 0, lng: 0 },
          image: "",
          dropzone: [],
        },
        rapids: [
          {
            name: "",
            class: "",
            distance: 0,
            gradient: 0,
            position: { lat: 0, lng: 0 },
            image: "",
            dropzone: [],
          },
        ],
        textFieldRules: [(value) => !!value || "Required."],
        isSaved: false,
        isModification: false,
        errorMessage: "",
        valid: true,
      };
    },
    async created() {
      await this.getTopo();
    },
    async updated() {
      if (this.reloadData) {
        await this.getTopo();
        this.$emit("update", false);
        this.isModification = false;
      }
    },
    methods: {
      async getTopo() {
        this.topo = await SectionsService.actions.findSectionsTopos(this.section.id);

        //this.rapids = this.topo.rapids;

        if (!this.topo.type) {
          this.$set(this.topo, "type", "external");
        }
      },
      handleChange() {
        this.isSaved = false;
        this.errorMessage = "";
        this.isModification = true;
      },
      updateTopoType(event) {
        this.topo.type = event;
        this.isModification = false;
      },
      addRapid(index) {
        this.rapids.splice(index, 0, {
          name: "",
          class: "",
          distance: 0,
          gradient: 0,
          position: { lat: 0, lng: 0 },
          image: "",
          dropzone: [],
        });
        this.handleChange();
      },
      deleteRapid(index) {
        this.rapids.splice(index, 1);
        this.handleChange();
      },
      addPhoto(index) {
        this.rapidIndex = index;
        this.dialogImport = true;
      },
      async uploadRapids() {
        this.uploadWaiting = true;
        var baseUrl = this.section.id + "/rapids";
        // CREATE AN ARRAY OF PROMISES
        let allOperations = this.photosDropzone.map((image) => {
          // FILE IN BLOB / FILE FORMAT
          return UploadService.actions.uploadImage(baseUrl, image);
        });

        // GET URLS
        let allFiles = await Promise.all(allOperations).then((result) => {
          return result
            .filter((res) => {
              return res.success;
            })
            .map((res) => {
              return { url: res.url, fileName: res.imageName };
            });
        });

        for (const i in allFiles) {
          this.rapids[this.rapidIndex].image({ url: allFiles[i].url, fileName: allFiles[i].fileName });
        }

        this.topo.rapids = this.rapids;

        await SectionsService.actions.setSectionsTopos(this.section.id, this.topo);
        this.photosDropzone = [];
        this.uploadWaiting = false;
        this.isModification = false;
      },
      async save() {
        if (this.valid) {
          this.topo.rapids = this.rapids;

          SectionsService.actions
            .setSectionsTopos(this.section.id, this.topo)
            .then(() => {
              this.isModification = false;
              this.isSaved = true;
              this.successMessage;
            })
            .catch((error) => {
              console.log(error);
              this.errorMessage =
                "Une erreur s'est produite lors de la sauvegarde de la topo, vérifiez les résultats et recommencez.";
            });
        } else {
          this.errorMessage = "Certains champs obligatoires ne répondent pas aux critères attendus, veuillez vérifier.";
        }
      },
    },
    watch: {
      "section.topo_info"(_o, _n) {
        SectionsService.actions.updateSection(this.section);
      },
      reloadData(_o, _n) {
        if (this.reloadData) {
          this.isSaved = false;
          this.errorMessage = "";
          this.isModification = false;
        }
      },
    },
  };
</script>

<style lang="scss" scoped></style>
