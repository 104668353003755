<template>
  <div>
    <v-container>
      <v-row>
        <v-col v-for="(video, index) in sectionVideos" :key="`video-${index}`" cols="12" md="4" sm="4">
          <v-card height="100%" hover>
            <v-card-text class="text-center">
              <video-embed :src="video.link"></video-embed>
            </v-card-text>
            <v-card-text>
              {{ video.description }}
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
  import { SectionsService } from "@/store/modules/sections";
  export default {
    props: {
      section: {
        type: Object,
        default: function() {
          return {};
        },
      },
    },
    data() {
      return {
        sectionVideos: [],
      };
    },
    async mounted() {
      if (this.section.video_info) {
        await this.getSectionVideos(this.section.id);
      }
    },
    methods: {
      async getSectionVideos(id) {
        const sectionVideosDocs = await SectionsService.actions.findSectionVideos(id);
        if (sectionVideosDocs) {
          const sectionVideoLinks = sectionVideosDocs;
          this.sectionVideos = JSON.parse(sectionVideoLinks.links);
        }
      },
    },
  };
</script>

<style lang="scss" scoped></style>
