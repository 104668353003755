import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";
import "firebase/functions";

import { firebaseConfig } from "@/config/firebase.config";

const app = firebase.initializeApp(firebaseConfig);

const db = firebase.firestore();
const auth = firebase.auth();
const functions = app.functions("northamerica-northeast1");

const { TimeStamp, GeoPoint } = firebase.firestore;

export { app, db, auth, functions, TimeStamp, GeoPoint };
