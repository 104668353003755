<template>
  <div>
    <v-toolbar color="primary darken-1" dark>
      <v-toolbar-title class="subtitle-1"> Liste des Jauges <small>(Administration)</small> </v-toolbar-title>
    </v-toolbar>

    <v-card>
      <v-btn class="" dark absolute top right fab color="success" @click="$router.push({ name: 'admin-gauge-add' })">
        <v-icon dark> mdi-plus </v-icon>
      </v-btn>
      <v-card-title>
        Jauges
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Rechercher"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>
      <v-data-table :headers="headers" :items="gauges" :search="search">
        <template v-slot:item.actions="{ item }">
          <v-icon small class="mr-2" @click="editGauge(item)" color="primary darken-1"> mdi-pencil </v-icon>
        </template>
        <template v-slot:item.active="{ item }">
          <v-icon v-if="item.active" color="green"> mdi-check </v-icon>
          <v-icon v-else color="red"> mdi-close </v-icon>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        search: "",
        headers: [
          {
            text: "Nom",
            align: "start",
            value: "name",
          },
          { text: "Description", value: "description" },
          { text: "Type", value: "type" },
          { text: "Actif", value: "active" },
          { text: "ID", value: "id" },
          { text: "Action", value: "actions" },
        ],
        gauges: [],
      };
    },
    async created() {
      this.gauges = await this.getAllGauges();
    },
    methods: {
      async getAllGauges() {
        return await this.$store.dispatch("bindGauges");
      },
      editGauge(gauge) {
        var slug = gauge.id;
        console.log("Edit gauge:", gauge);
        this.$router.push({ name: "admin-gauge-edit", params: { slug: slug, gauge: gauge } });
      },
    },
  };
</script>
