//import riversDB from "@/store/db/qww_river.db";
import { firestoreAction } from "vuexfire";
import { db } from "@/firebase";

const riversRef = db.collection("rivers");

export const RiversService = {
  state: {
    rivers: [],
  },
  getters: {
    rivers: (state) => {
      return state.rivers;
    },
  },
  actions: {
    bindRivers: firestoreAction(({ bindFirestoreRef }) => {
      // return the promise returned by `bindFirestoreRef`
      return bindFirestoreRef("rivers", riversRef.orderBy("name"));
    }),
    async riverExistByAlias(riverAlias) {
      const snapshot = await riversRef.where("alias", "==", riverAlias).get();

      var id = null;
      //var data = null;

      if (!snapshot.empty) {
        snapshot.docs.forEach((doc) => {
          id = doc.id;
          //data = doc.data(); //unused for now
        });
      }
      return id;
    },
    async addRiver(river) {
      river.alias = this.createAlias(river.name);
      const result = await riversRef.add(river);
      return result.id;
    },
    async deleteRiver(river) {
      const riverRefDelete = riversRef.doc(river.id);
      await riverRefDelete.delete();
    },
    createAlias(stringToAlias) {
      stringToAlias = stringToAlias.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
      return stringToAlias
        .replace(/[^a-z0-9\s]/gi, "")
        .trim()
        .replace(/\s+/g, "-")
        .toLowerCase();
    },
  },
};
