<template>
  <v-container>
    <h1>Compte</h1>
    <div v-if="loading">Chargement des informations...</div>
    <!-- <div v-else-if="!subscription">
      Ajouter le component de subscription
      <pre>{{ this.subscription }}</pre>
    </div> -->
    <subscribedAccount v-else :subscription="subscription"></subscribedAccount>
  </v-container>
</template>

<script>
  import { UserService } from "@/store/modules/user";
  import subscribedAccount from "@/components/pages/subscription/SubscribedAccount.vue";
  export default {
    components: { subscribedAccount },
    data() {
      return {
        loading: true,
        subscription: [],
      };
    },
    methods: {},
    async mounted() {
      this.loading = true;
      this.subscription = await UserService.actions.fetchCustomerSubscriptions();
      this.loading = false;
    },
    async created() {},
  };
</script>
