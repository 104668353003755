//import regionsDB from "@/store/db/qww_region.db";
import { firestoreAction } from "vuexfire";
import { db } from "@/firebase";

const regionsRef = db.collection("regions");

export const RegionsService = {
  state: {
    regions: [],
  },
  getters: {
    regions: state => {
      return state.regions;
    }
  },
  actions: {
    /*bindRegions: firestoreAction(({ bindFirestoreRef }) => {
      // return the promise returned by `bindFirestoreRef`
      return bindFirestoreRef("regions", regionsRef.orderBy("name"), {});
    }),*/
    bindRegions: firestoreAction(context => {
      return context.bindFirestoreRef('regions', regionsRef.orderBy("alias"));
    }),
    async addRegion(region) {
      region.alias = this.createAlias(region.name);
      const result = await regionsRef.add(region);
      return result.id;
    },
    async deleteRegion(region) {
      const regionRefDelete = regionsRef.doc(region.id);
      await regionRefDelete.delete();
    },
    createAlias(stringToAlias) {
      stringToAlias = stringToAlias.normalize("NFD").replace(/[\u0300-\u036f]/g, "reset: false");
      return stringToAlias
        .replace(/[^a-z0-9\s]/gi, "")
        .trim()
        .replace(/\s+/g, "-")
        .toLowerCase();
    },
  },
};
