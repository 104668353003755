import { auth, db, functions } from "@/firebase";

const userRoleRef = db.collection("userRole");
const userProfileRef = db.collection("userProfile");

export const UserService = {
  state: {
    user: [],
  },
  actions: {
    async getUserProfileByUid(uid) {
      const userProfileDoc = userProfileRef.doc(uid);
      const userProfileSnap = await userProfileDoc.get();

      if (userProfileSnap.exists) {
        return userProfileSnap.data();
      } else {
        console.log("Not matching user profile");
        return null;
      }
    },
    async getUserFiltersByUid(uid) {
      const userProfileDoc = userProfileRef.doc(uid);
      const userProfileSnap = await userProfileDoc.get();

      if (userProfileSnap.exists) {
        const userProfile = userProfileSnap.data();
        return JSON.parse(userProfile.filters);
      } else {
        return { regions: [], levels: [], classes: [] };
      }
    },
    async setUserProfile(uid, userProfile) {
      const userProfileDoc = userProfileRef.doc(uid);
      const res = userProfileDoc.set(
        {
          filters: userProfile.filters,
          likedSections: userProfile.likedSections,
        },
        { merge: true }
      );

      return res;
    },
    async setUserFilters(uid, userFilters) {
      const userProfileDoc = userProfileRef.doc(uid);
      const res = userProfileDoc.set(
        {
          filters: JSON.stringify(userFilters),
        },
        { merge: true }
      );

      return res;
    },
    async getUserRoleByUid(uid) {
      const userRoleDoc = userRoleRef.doc(uid);
      const userRoleSnap = await userRoleDoc.get();

      if (userRoleSnap.exists) {
        return userRoleSnap.data();
      } else {
        console.log("Not matching user role");
        const userMembershipInfo = { id: uid, access: "aucun", endDate: null, note: "" };
        const setUserMembership = functions.httpsCallable("setUserMembership");
        const userRole = await setUserMembership({ userRole: userMembershipInfo })
          .then((result) => {
            return result;
          })
          .then((error) => {
            return error;
          });

        return userRole.data;
      }
    },
    async getUserRoles(uid) {
      var isAdmin = false;
      var isMember = false;

      const userRole = await this.getUserRoleByUid(uid);
      if (userRole) {
        if (userRole.access === "admin") {
          isAdmin = true;
          isMember = true;
        } else if (userRole.access === "member") {
          var todayMinusOne = new Date();
          todayMinusOne.setDate(todayMinusOne.getDate() - 1);

          var membershipEndDate = userRole.endDate;

          if (membershipEndDate > todayMinusOne.getTime() / 1000) {
            isMember = true;
          }
        }
      }
      return { isAdmin: isAdmin, isMember: isMember, membershipEndDate: userRole ? userRole.endDate : null };
    },
    async isMembershipActive(uid) {
      const userRole = await this.getUserRoleByUid(uid);
      if (userRole) {
        if (userRole.access === "admin") {
          return true;
        } else if (userRole.access === "member") {
          var todayMinusOne = new Date();
          todayMinusOne.setDate(todayMinusOne.getDate() - 1);

          var membershipEndDate = userRole.endDate;

          if (!membershipEndDate) {
            return false;
          }
          if (membershipEndDate > todayMinusOne.getTime() / 1000) {
            return true;
          }
        }
      } else {
        return false;
      }
      return false;
    },
    async addUserRoleMember(uid, endDate) {
      const userRoleDoc = userRoleRef.doc(uid);
      const res = userRoleDoc.set(
        {
          access: "member",
          endDate: endDate,
        },
        { merge: true }
      );

      return res;
    },
    async fetchCustomerSubscriptions() {
      const subscriptionsRef = db
        .collection("stripeCustomers")
        .doc(auth.currentUser.uid)
        .collection("subscriptions");
      const subscriptionsQuery = subscriptionsRef.where("status", "in", ["active", "trialing", "past_due", "unpaid"]);

      return await subscriptionsQuery.get().then((sub) => (sub.docs.length > 0 ? sub.docs[0].data() : null));
    },
  },
};
