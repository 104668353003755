<template>
  <div id="sections">
    <v-toolbar color="primary darken-1" dark fixed>
      <v-toolbar-title class="subtitle-1">
        Liste des rivières
      </v-toolbar-title>
    </v-toolbar>
    <div>
      <v-fab-transition>
        <v-btn class="mt-n9" color="blue" fab dark small fixed right @click="filterDrawer = !filterDrawer">
          <v-icon v-if="!emptyFilters">mdi-filter-menu</v-icon>
          <v-icon v-if="emptyFilters">mdi-filter-menu-outline</v-icon>
        </v-btn>
      </v-fab-transition>
      <v-navigation-drawer v-model="filterDrawer" temporary app>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title class="title">
              Filtres
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider class="mt-2"></v-divider>
        <v-text-field v-model="search" label="Recherche par nom" clearable></v-text-field>
        <v-list dense nav>
          <v-list-group :value="false">
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title>
                  <strong>Régions</strong>
                  <a
                    class="text-right"
                    style="float: right;"
                    v-if="searchCriterias.regions.length > 0"
                    @click="clearRegionFilters()"
                  >
                    <v-icon small>mdi-filter</v-icon> (Effacer tout)
                  </a>
                </v-list-item-title>
              </v-list-item-content>
            </template>
            <v-list-item-group v-model="searchCriterias.regions" multiple>
              <template v-for="(item, i) in regions">
                <v-divider v-if="!item" :key="`divider-${i}`"></v-divider>

                <v-list-item v-else :key="item.name" :value="item.name" active-class="primary--text text--accent-4">
                  <template v-slot:default="{ active }">
                    <v-list-item-content>
                      <v-list-item-title v-text="item.name"></v-list-item-title>
                    </v-list-item-content>

                    <v-list-item-action>
                      <v-checkbox :input-value="active" color="primary accent-4"></v-checkbox>
                    </v-list-item-action>
                  </template>
                </v-list-item>
              </template>
            </v-list-item-group>
          </v-list-group>
          <v-list-group>
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title>
                  <strong>Classes</strong>
                  <a
                    class="text-right"
                    style="float: right;"
                    v-if="searchCriterias.classes.length > 0"
                    @click="clearClassificationFilters()"
                  >
                    <v-icon small>mdi-filter</v-icon> (Effacer tout)
                  </a>
                </v-list-item-title>
              </v-list-item-content>
            </template>
            <v-list-item-group v-model="searchCriterias.classes" multiple>
              <template v-for="(item, i) in classes">
                <v-divider v-if="!item" :key="`divider-${i}`"></v-divider>

                <v-list-item
                  v-else
                  :key="item.class_level"
                  :value="item.class_level"
                  active-class="primary--text text--accent-4"
                >
                  <template v-slot:default="{ active }">
                    <v-list-item-content>
                      <v-list-item-title v-text="item.class_level"></v-list-item-title>
                    </v-list-item-content>

                    <v-list-item-action>
                      <v-checkbox :input-value="active" color="primary accent-4"></v-checkbox>
                    </v-list-item-action>
                  </template>
                </v-list-item>
              </template>
            </v-list-item-group>
          </v-list-group>
          <v-list-group :value="false">
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title>
                  <strong>Niveaux</strong>
                  <a
                    class="text-right"
                    style="float: right;"
                    v-if="searchCriterias.levels.length > 0"
                    @click="clearLevelFilters()"
                  >
                    <v-icon small>mdi-filter</v-icon> (Effacer tout)
                  </a>
                </v-list-item-title>
              </v-list-item-content>
            </template>
            <v-list-item-group v-model="searchCriterias.levels" multiple>
              <template v-for="(item, i) in levels">
                <v-divider v-if="!item" :key="`divider-${i}`"></v-divider>

                <v-list-item
                  v-else
                  :key="`item-${i}.text`"
                  :value="item.value"
                  :active-class="item.color + '--text text--accent-4'"
                >
                  <template v-slot:default="{ active }">
                    <v-list-item-content>
                      <v-list-item-title v-text="item.text"></v-list-item-title>
                    </v-list-item-content>

                    <v-list-item-action>
                      <v-checkbox :input-value="active" :color="item.color + ' accent-4'"></v-checkbox>
                    </v-list-item-action>
                  </template>
                </v-list-item>
              </template>
            </v-list-item-group>
          </v-list-group>
        </v-list>
      </v-navigation-drawer>
      <sectionDataTable :sections="filteredSections" :search="search" :isMember="isMember"></sectionDataTable>
    </div>
  </div>
</template>

<script>
  import { auth } from "@/firebase";
  import { UserService } from "@/store/modules/user";
  import SectionDataTable from "./components/SectionDataTable.vue";
  import { RiversService } from "../../../services/Rivers.service";
  export default {
    components: { SectionDataTable },
    name: "sections",
    props: {
      isMember: Boolean,
    },
    data() {
      return {
        filterDrawer: false,
        search: "",
        loadingSection: true,
        searchCriterias: { regions: [], classes: [], levels: [] },
        searchCriteriasDb: { regions: [], classes: [], levels: [] },
        classes: RiversService.getClassLevels(),
        class_checkboxes: [],
        selected_regions: [],
        selected_classes: [],
        selected_levels: [],
      };
    },
    async beforeCreate() {
      await auth.onAuthStateChanged(async (user) => {
        if (user) {
          this.searchCriterias = await UserService.actions.getUserFiltersByUid(user.uid);
          this.searchCriteriasDb = JSON.parse(JSON.stringify(this.searchCriterias));
        }
      });
    },
    async created() {
      await this.getRegions();
      await this.getLevels();

      await this.getSections();
      this.loadingSection = false;
    },
    async mounted() {},
    methods: {
      async getRegions() {
        await this.$store.dispatch("bindRegions");
      },
      async saveFiltersPreferences() {
        await UserService.actions.setUserFilters(auth.currentUser.uid, this.searchCriterias);
        this.searchCriteriasDb = JSON.parse(JSON.stringify(this.searchCriterias));
      },
      clearRegionFilters() {
        this.searchCriterias.regions = [];
      },
      clearClassificationFilters() {
        this.searchCriterias.classes = [];
      },
      clearLevelFilters() {
        this.searchCriterias.levels = [];
      },
      async getSections() {
        await this.$store.dispatch("bindSections");
      },
      async getLevels() {
        await this.$store.dispatch("fetchLevels");
      },
      filterRiversByRegion: function(rivers) {
        return this.searchCriterias.regions.length > 0
          ? rivers.filter((river) => this.searchCriterias.regions.includes(river.region))
          : rivers;
      },
      filterRiversByClass: function(rivers) {
        return this.searchCriterias.classes.length > 0
          ? rivers.filter((river) => this.searchCriterias.classes.includes(river.class_level))
          : rivers;
      },
      filterRiversByLevelStatus: function(rivers) {
        return this.searchCriterias.levels.length > 0
          ? rivers.filter((river) => this.searchCriterias.levels.includes(river.level_status))
          : rivers;
      },
      changeClassesState(selected_class, event_is_pressed) {
        const river_class = this.classes.find(({ class_level }) => class_level === selected_class);
        if (event_is_pressed) {
          river_class.sub_classes.forEach((sub_class) => {
            this.searchCriterias.classes.push(sub_class);
          });
        } else {
          river_class.sub_classes.forEach((sub_class) => {
            const idx = this.searchCriterias.classes.indexOf(sub_class);
            this.searchCriterias.classes.splice(idx, 1);
          });
        }
      },
    },
    computed: {
      regions() {
        return this.$store.getters.regions;
      },
      sections() {
        return this.$store.getters.sections;
      },
      levels() {
        return this.$store.getters.levels;
      },
      filteredSections() {
        const sections = this.$store.getters.sections;
        return this.filterRiversByRegion(this.filterRiversByClass(this.filterRiversByLevelStatus(sections)));
      },
      emptyFilters() {
        if (this.searchCriterias.regions.length > 0) {
          return false;
        }

        if (this.searchCriterias.levels.length > 0) {
          return false;
        }

        if (this.searchCriterias.classes.length > 0) {
          return false;
        }

        if (this.search) {
          if (this.search.length > 0) {
            return false;
          }
        }
        return true;
      },
    },
    watch: {
      searchCriterias: {
        deep: true,
        handler() {
          if (JSON.stringify(this.searchCriterias) !== JSON.stringify(this.searchCriteriasDb)) {
            this.saveFiltersPreferences();
          }
        },
        immediate: false,
      },
    },
  };
</script>

<style lang="scss" scoped></style>
