<template>
  <div>
    <div class="text-center">
      <v-dialog v-model="dialogImportKML" width="500">
        <v-card>
          <v-card-title class="headline grey lighten-2">
            Importation des coordonnées KML
          </v-card-title>
          <v-alert border="bottom" colored-border type="warning" elevation="2">
            ATTENTION! Cette action supprimera les coordonées de la section actuelles.
          </v-alert>
          <v-card-text>
            <v-textarea solo v-model="importKMLText" label="Insérer les coordonnées ici"></v-textarea>
            <v-btn color="primary" class="ma-2 white--text" @click="ImportKML()" :disabled="importKMLText.length == 0">
              Importer les données
            </v-btn>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="dialogImportKML = false">
              Annuler l'action
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="dialogMapAction" width="500">
        <v-card>
          <v-card-title class="headline grey lighten-2">
            Quelle action souhaitez-vous apporter?
          </v-card-title>

          <v-card-text>
            <v-btn text color="primary" class="ma-2 white--text" @click="addMarker('putin')">
              Ajouter un Put In
            </v-btn>
          </v-card-text>
          <v-card-text>
            <v-btn text color="primary" class="ma-2 white--text" @click="addMarker('takeout')">
              Ajouter un Take Out
            </v-btn>
          </v-card-text>
          <v-card-text v-if="sectionPath.length < 2">
            <v-btn text color="primary" class="ma-2 white--text" @click="addSectionPath()">
              Ajouter la section
            </v-btn>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="dialogMapAction = false">
              Annuler l'action
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
    <v-fab-transition v-if="isModification">
      <v-btn v-hotkey="'ctrl+s'" color="green" fab dark fixed bottom right @click="save">
        <v-icon large>mdi-content-save-outline</v-icon>
      </v-btn>
    </v-fab-transition>
    <v-snackbar v-model="isSaved" color="success">
      La section a été sauvegardée avec succès.

      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="isSaved = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
    <v-container>
      <v-row>
        <v-col>
          <v-alert v-if="errorMessage" dense text border="left" type="error" elevation="2">
            {{ errorMessage }}
          </v-alert>
          <v-alert v-if="isModification" dense text border="left" type="warning" elevation="2">
            Attention des modifications semblent en cours dans cet onglet. Veuillez vous assurer de sauvegarder avant de
            changer de section ou d'onglet. (Appuyer sur CTRL+S pour sauvegarder)
          </v-alert>
        </v-col>
      </v-row>
      <v-row class="mb-4">
        <v-col>
          <v-switch v-model="section.direction_info" label="Activer la section Directions" class="mb-n6"></v-switch>
          <small class="text--secondary"> (L'activation de la section sera sauvegardée automatiquement)</small>
        </v-col>
        <v-col>
          <v-btn depressed color="primary" @click="dialogImportKML = true">
            Importer les positions KML
          </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-sheet :height="gmapHeight" elevation="8">
            <gmaps-map ref="sectionMaps" :options="mapOptions" @click="setCursorInfo">
              <gmaps-marker
                v-for="(marker, markerIndex) in markers"
                :key="`marker-${markerIndex}`"
                :options="marker.options"
                @click="removeMarker(markerIndex)"
              />
              <gmaps-polyline
                :editable="editable"
                :path="sectionPath"
                stroke-color="dodgerblue"
                stroke-weight="5"
                @pathChanged="updateSectionPath($event)"
              />
            </gmaps-map>
          </v-sheet>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
  import { SectionsService } from "@/store/modules/sections";
  import { gmapsMap, gmapsMarker, gmapsPolyline } from "x5-gmaps";
  import { GmapsService } from "@/services/Gmaps.service";

  export default {
    components: { gmapsMap, gmapsMarker, gmapsPolyline },
    props: {
      section: {
        type: Object,
        default: function() {
          return {};
        },
      },
      reloadData: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        gmaps_key: process.env.VUE_APP_GOOGLE_MAPS_KEY,
        mapOptions: {
          mapTypeId: "terrain",
          center: GmapsService.BASE_CENTER_POSITION,
          zoom: 12,
        },
        directions: {},
        markers: [],
        sectionPath: [],
        editable: true,
        isSaved: false,
        isModification: false,
        errorMessage: "",
        valid: true,
        dialogMapAction: false,
        dialogImportKML: false,
        importKMLText: "",
        currentCursorInfo: {},
      };
    },
    async created() {
      await this.getDirections();
      //this.watchMarkers();
    },
    async updated() {
      if (this.reloadData) {
        await this.getDirections();
        //this.watchMarkers();
        this.$emit("update", false);
      }
    },
    computed: {
      gmapHeight() {
        switch (this.$vuetify.breakpoint.name) {
          case "xs":
            return "220px";
          case "sm":
            return "400px";
          case "md":
            return "500px";
          case "lg":
            return "600px";
          case "xl":
            return "800px";
        }
        return "400px";
      },
    },
    methods: {
      async getDirections() {
        this.directions = await SectionsService.actions.findSectionsDirections(this.section.id);
        this.setMarkers();
        this.setSectionPath();
      },
      watchMarkers() {
        this.markers.forEach((marker) => {
          this.$watch(() => marker, this.handleChange(), { deep: true });
        });
      },
      watchSectionPath() {
        this.sectionPath.forEach((sectionPath) => {
          this.$watch(() => sectionPath, this.handleChange(), { deep: true });
        });
      },
      handleChange() {
        this.isSaved = false;
        this.errorMessage = "";
        this.isModification = true;
      },
      setSectionPath() {
        this.sectionPath = [];
        if (this.directions.sectionPath) {
          if (this.directions.sectionPath.length > 0) this.sectionPath = JSON.parse(this.directions.sectionPath);
        }
      },
      setMarkers() {
        this.markers = [];

        if (!this.directions.markers) {
          this.getOldMarkers();
        } else if (this.directions.markers.length == 0) {
          this.getOldMarkers();
        } else {
          this.markers = JSON.parse(this.directions.markers);
        }

        this.getSVGsLabelOrigin();
        this.setCenterPosition();
      },
      setCenterPosition() {
        if (this.markers.length > 0) {
          this.mapOptions.center = GmapsService.averageGeolocation(this.markers);
        } else {
          this.mapOptions.center = GmapsService.BASE_CENTER_POSITION;
        }
      },
      getOldMarkers() {
        if (this.directions.takeout.length > 0)
          if (GmapsService.getLatLngFromStringLatLng(this.directions.takeout))
            this.markers.push({
              type: "putin",
              options: {
                position: GmapsService.getLatLngFromStringLatLng(this.directions.takeout),
                label: GmapsService.LABEL_PUTIN,
                icon: GmapsService.SVG_PUTIN,
              },
            });

        if (this.directions.putin.length > 0)
          if (GmapsService.getLatLngFromStringLatLng(this.directions.putin))
            this.markers.push({
              type: "takeout",
              options: {
                position: GmapsService.getLatLngFromStringLatLng(this.directions.putin),
                label: GmapsService.LABEL_TAKEOUT,
                icon: GmapsService.SVG_TAKEOUT,
              },
            });
      },
      getSVGsLabelOrigin() {
        var point = new window.google.maps.Point(12, 30);
        var svgPutin = GmapsService.SVG_PUTIN;
        var svgTakeout = GmapsService.SVG_TAKEOUT;
        svgPutin.labelOrigin = point;
        svgTakeout.labelOrigin = point;

        return { putin: svgPutin, takeout: svgTakeout };
      },
      setCursorInfo($event) {
        this.currentCursorInfo = $event.latLng.toJSON();
        this.dialogMapAction = true;
      },
      addMarker(type) {
        if (type == "putin") {
          this.markers.push({
            type: "putin",
            options: {
              position: this.currentCursorInfo,
              label: GmapsService.LABEL_PUTIN,
              icon: GmapsService.SVG_PUTIN,
            },
          });
        }
        if (type == "takeout") {
          this.markers.push({
            type: "takeout",
            options: {
              position: this.currentCursorInfo,
              label: GmapsService.LABEL_TAKEOUT,
              icon: GmapsService.SVG_TAKEOUT,
            },
          });
        }
        this.dialogMapAction = false;
        this.handleChange();
      },
      removeMarker(index) {
        if (this.markers[index].options.label.text == "Put In") {
          this.directions.putin = "";
        }

        if (this.markers[index].options.label.text == "Take Out") {
          this.directions.takeout = "";
        }
        this.markers.splice(index, 1);
        this.handleChange();
      },
      addSectionPath() {
        this.sectionPath.push(this.currentCursorInfo);
        this.dialogMapAction = false;
        this.handleChange();
      },
      updateSectionPath(event) {
        this.sectionPath = event;
        this.handleChange();
      },
      removeSectionPath(index) {
        this.sectionPath.splice(index, 1);
        this.handleChange();
      },
      async save() {
        if (this.valid) {
          this.$set(this.directions, "markers", JSON.stringify(this.markers));
          this.$set(this.directions, "sectionPath", JSON.stringify(this.sectionPath));

          SectionsService.actions
            .setSectionsDirections(this.section.id, this.directions)
            .then(() => {
              this.isModification = false;
              this.isSaved = true;
              this.successMessage;
            })
            .catch((error) => {
              console.log(error);
              this.errorMessage =
                "Une erreur s'est produite lors de la sauvegarde des directions, vérifiez les résultats et recommencez.";
            });
        } else {
          this.errorMessage = "Certains champs obligatoires ne répondent pas aux critères attendus, veuillez vérifier.";
        }
      },
      async ImportKML() {
        this.sectionPath = GmapsService.convertKmlCoordinates(this.importKMLText);
        await this.save();
        this.dialogImportKML = false;
      },
    },
    watch: {
      "section.direction_info"(_o, _n) {
        SectionsService.actions.updateSection(this.section);
      },
      reloadData(_o, _n) {
        if (this.reloadData) {
          this.isSaved = false;
          this.errorMessage = "";
          this.isModification = false;
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
  #gmap {
    height: 500px;
  }
</style>
