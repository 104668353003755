<template>
  <div>
    <v-fab-transition v-if="isModification">
      <v-btn v-hotkey="'ctrl+s'" color="green" fab dark fixed bottom right @click="save">
        <v-icon large>mdi-content-save-outline</v-icon>
      </v-btn>
    </v-fab-transition>
    <v-snackbar v-model="isSaved" color="success">
      La section a été sauvegardée avec succès.

      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="isSaved = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
    <v-container>
      <v-row class="mb-4">
        <v-col>
          <v-row>
            <v-col>
              <v-switch v-model="section.image_info" label="Activer la section Images" class="mb-n6"></v-switch>
              <small class="text--secondary"> (L'activation de la section sera sauvegardée automatiquement)</small>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row>
        <v-col v-if="uploadWaiting">
          <div class="text-center">
            <v-progress-circular :size="50" color="primary" indeterminate></v-progress-circular>
            <p class="blue--text">Veuillez patienter durant le téléchargement.</p>
          </div>
        </v-col>
        <v-col v-if="!uploadWaiting">
          <FileDrop :files="photosDropzone" accept=".jpg,.JPG" label multiple></FileDrop>
        </v-col>
      </v-row>
      <v-row v-if="photosDropzone.length !== 0">
        <v-col class="d-flex justify-center">
          <v-btn class="primary" @click="uploadPhotos()" :disabled="uploadWaiting" :loading="uploadWaiting">
            <v-icon left dark>mdi-cloud-upload</v-icon>
            Télécharger les images
          </v-btn>
        </v-col>
      </v-row>
      <v-form ref="sectionPhotos" v-model="valid">
        <v-row>
          <v-col>
            <v-alert v-if="errorMessage" dense text border="left" type="error" elevation="2">
              {{ errorMessage }}
            </v-alert>
            <v-alert v-if="isModification" dense text border="left" type="warning" elevation="2">
              Attention des modifications semblent en cours dans cet onglet. Veuillez vous assurer de sauvegarder avant
              de changer de section ou d'onglet. (Appuyer sur CTRL+S pour sauvegarder)
            </v-alert>
          </v-col>
        </v-row>
        <v-row>
          <draggable v-model="photos" class="row" @change="handleChangePhotos">
            <v-col v-for="(photo, i) in photos" :key="photo.url" cols="4">
              <v-card flat>
                <v-img
                  :src="photo.url"
                  class="white--text align-end"
                  gradient="to top, rgba(0,0,0,.6), rgba(0,0,0,0)"
                  max-height="300"
                  max-width="300"
                >
                  <v-card-title v-text="photo.description" class="mb-n3 ml-n2 caption"></v-card-title>
                </v-img>
                <v-card-actions>
                  <v-text-field label="Description" v-model="photo.description" class="ml-n4" clearable></v-text-field>
                  <v-btn icon color="error" @click="removeImage(i)">
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-col>
          </draggable>
        </v-row>
      </v-form>
    </v-container>
  </div>
</template>

<script>
  import draggable from "vuedraggable";
  import FileDrop from "@/components/FileDrop";
  import { UploadService } from "@/store/modules/upload";
  import { SectionsService } from "@/store/modules/sections";

  export default {
    components: {
      FileDrop,
      draggable,
    },
    props: {
      section: {
        type: Object,
        default: function() {
          return {};
        },
      },
      reloadData: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        photosDropzone: [],
        uploadWaiting: false,
        photos: [],
        isSaved: false,
        isModification: false,
        errorMessage: "",
        valid: true,
      };
    },
    async created() {
      await this.getPhotos();
    },
    async updated() {
      if (this.reloadData) {
        await this.getPhotos();
        this.$emit("update", false);
      }
    },
    methods: {
      async getPhotos() {
        const photosString = await SectionsService.actions.findSectionPhotos(this.section.id);
        this.photos = photosString ? JSON.parse(photosString.links) : [];
        this.watchPhotos();
      },
      watchPhotos() {
        this.photos.forEach((val) => {
          this.$watch(() => val, this.handleChangePhotos, { deep: true });
        });
      },
      handleChangePhotos() {
        this.isSaved = false;
        this.errorMessage = "";
        this.isModification = true;
      },
      async uploadPhotos() {
        this.uploadWaiting = true;
        // CREATE AN ARRAY OF PROMISES
        let allOperations = this.photosDropzone.map((image) => {
          // FILE IN BLOB / FILE FORMAT
          return UploadService.actions.uploadImage(this.section.id, image);
        });

        // GET URLS
        let allFiles = await Promise.all(allOperations).then((result) => {
          return result
            .filter((res) => {
              return res.success;
            })
            .map((res) => {
              return { url: res.url, fileName: res.imageName };
            });
        });

        for (const i in allFiles) {
          this.photos.push({ url: allFiles[i].url, fileName: allFiles[i].fileName, description: "" });
        }

        await SectionsService.actions.setSectionsPhotos(this.section.id, this.photos);
        this.watchPhotos();
        this.photosDropzone = [];
        this.uploadWaiting = false;
      },
      removeImage(index) {
        UploadService.actions
          .deleteImage(this.section.id, this.photos[index].fileName)
          .then(this.photos.splice(index, 1))
          .then(SectionsService.actions.setSectionsPhotos(this.section.id, this.photos));
      },
      async save() {
        if (this.valid) {
          SectionsService.actions
            .setSectionsPhotos(this.section.id, this.photos)
            .then(() => {
              this.isModification = false;
              this.isSaved = true;
              this.successMessage;
            })
            .catch((error) => {
              console.log(error);
              this.errorMessage =
                "Une erreur s'est produite lors de la sauvegarde des vidéos, vérifiez les résultats et recommencez.";
            });
        } else {
          this.errorMessage = "Certains champs obligatoires ne répondent pas aux critères attendus, veuillez vérifier.";
        }
      },
    },
    watch: {
      "section.image_info"(_o, _n) {
        SectionsService.actions.updateSection(this.section);
      },
      reloadData(_o, _n) {
        if (this.reloadData) {
          this.isSaved = false;
          this.errorMessage = "";
          this.isModification = false;
        }
      },
    },
  };
</script>

<style lang="scss" scoped></style>
