export default [
    {
       "value": "unknown",
       "text": "Inconnu",
       "color": ""
    },
    {
       "value": "low",
       "text": "Bas",
       "color": "red"
    },
    {
       "value": "recommended",
       "text": "Recommandé",
       "color": "green"
    },
    {
       "value":"high",
       "text": "Haut",
       "color": "blue"
    },
 ]