<template>
  <div>
    <v-toolbar color="primary darken-1" dark>
      <v-toolbar-title class="subtitle-1"> Liste des sections <small>(Administration)</small> </v-toolbar-title>
    </v-toolbar>
    <v-row>
      <v-col cols="2" class="mt-4">
        <div class="sticky-top pt-4">
          <v-btn small color="primary" dark @click="addSection()">
            <v-icon left dark> mdi-map-plus </v-icon>
            Ajouter une section
          </v-btn>
          <v-list color="transparent" height="100vh" dense class="mr-n3 scrollable">
            <v-list-item-group v-model="selectedSection" color="primary">
              <v-list-item v-for="(section, i) in sections" :key="i" :value="section" link two-line>
                <v-list-item-content>
                  <v-list-item-title>
                    <span class="blue--text">{{ section.region }}</span> <br />
                    {{ section.river }} - {{ section.class_level }}
                    <span v-if="section.class_level_max"> ({{ section.class_level_max }}) </span>
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    {{ section.name }}
                    <v-item-group>
                      <v-icon :color="getIconColor(section.image_info)" title="Images disponibles" small>
                        mdi-image-multiple
                      </v-icon>
                      <v-icon :color="getIconColor(section.video_info)" title="Vidéos disponibles" small
                        >mdi-file-video</v-icon
                      >
                      <v-icon :color="getIconColor(section.topo_info)" title="Cartes topo disponibles" small>
                        mdi-map-legend
                      </v-icon>
                      <v-icon :color="getIconColor(section.direction_info)" title="Directions disponibles" small>
                        mdi-google-maps
                      </v-icon>
                      <v-icon v-if="!section.published" color="red" title="Section non publiée" small>
                        mdi-eye-off
                      </v-icon>
                      <v-icon v-if="section.free_access" color="green" title="Section gratuite" small>
                        mdi-currency-usd-off
                      </v-icon>
                    </v-item-group>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </div>
      </v-col>
      <v-col>
        <v-sheet min-height="100vh" rounded="lg" class="mt-4 mr-4">
          <v-alert v-if="!selectedSection" border="left" colored-border type="info" elevation="2">
            Veuillez sélectionner une section dans le menu de gauche.
          </v-alert>
          <div v-if="selectedSection">
            <h2>
              {{ selectedSection.river }} - {{ selectedSection.name }}
              <small class="small-title">(alias : {{ selectedSection.alias }} | id : {{ selectedSection.id }})</small>
            </h2>
            <v-stepper class="mt-12" non-linear value="1">
              <v-stepper-header>
                <v-stepper-step editable step="1"> Information générale </v-stepper-step>
                <v-divider></v-divider>
                <v-stepper-step :editable="isAdded" step="2"> Conditions actuelles </v-stepper-step>
                <v-divider></v-divider>
                <v-stepper-step :editable="isAdded" step="3"> Topographie </v-stepper-step>
                <v-divider></v-divider>
                <v-stepper-step :editable="isAdded" step="4"> Photos </v-stepper-step>
                <v-divider></v-divider>
                <v-stepper-step :editable="isAdded" step="5"> vidéos </v-stepper-step>
                <v-divider></v-divider>
                <v-stepper-step :editable="isAdded" step="6"> Directions </v-stepper-step>
              </v-stepper-header>
              <v-stepper-content step="1">
                <SectionGeneral
                  :section="selectedSection"
                  :reloadData="reloadData"
                  @update="reloadData = $event"
                ></SectionGeneral>
              </v-stepper-content>
              <v-stepper-content step="2">
                <SectionFlow
                  :section="selectedSection"
                  :reloadData="reloadData"
                  @update="reloadData = $event"
                ></SectionFlow>
              </v-stepper-content>
              <v-stepper-content step="3">
                <SectionTopos
                  :section="selectedSection"
                  :reloadData="reloadData"
                  @update="reloadData = $event"
                ></SectionTopos>
              </v-stepper-content>
              <v-stepper-content step="4">
                <SectionPhotos
                  :section="selectedSection"
                  :reloadData="reloadData"
                  @update="reloadData = $event"
                ></SectionPhotos>
              </v-stepper-content>
              <v-stepper-content step="5">
                <SectionVideos
                  :section="selectedSection"
                  :reloadData="reloadData"
                  @update="reloadData = $event"
                ></SectionVideos>
              </v-stepper-content>
              <v-stepper-content step="6">
                <SectionDirections
                  :section="selectedSection"
                  :reloadData="reloadData"
                  @update="reloadData = $event"
                ></SectionDirections>
              </v-stepper-content>
            </v-stepper>
          </div>
        </v-sheet>
      </v-col>
    </v-row>
  </div>
</template>

<script>
  import { RiversService } from "@/services/Rivers.service";
  import { SectionsService } from "@/store/modules/sections";
  import SectionGeneral from "@/components/pages/admin/SectionGeneral";
  import SectionFlow from "@/components/pages/admin/SectionFlow";
  import SectionTopos from "@/components/pages/admin/SectionTopos";
  import SectionPhotos from "@/components/pages/admin/SectionPhotos";
  import SectionVideos from "@/components/pages/admin/SectionVideos";
  import SectionDirections from "@/components/pages/admin/SectionDirections";
  export default {
    components: {
      SectionGeneral,
      SectionFlow,
      SectionTopos,
      SectionPhotos,
      SectionVideos,
      SectionDirections,
    },
    data() {
      return {
        selectedSection: null,
        reloadData: false,
      };
    },
    async created() {
      await this.getSections();
    },
    methods: {
      async getSections() {
        await this.$store.dispatch("bindSectionsAdmin");
      },
      getIconColor(isActive) {
        return RiversService.getSectionIconColor(isActive);
      },
      addSection() {
        SectionsService.actions
          .addSection({
            alias: "",
            name: "",
            river: "",
          })
          .catch((error) => {
            console.log(error);
            this.errorMessage =
              "Une erreur s'est produite lors de la sauvegarde de la section, vérifiez les résultats et recommencez.";
          });
      },
    },
    computed: {
      sections() {
        function regionRiverSection(a, b) {
          if (a.region < b.region) return -1;
          if (a.region > b.region) return 1;
          if (a.river < b.river) return -1;
          if (a.river > b.river) return 1;
          if (a.name < b.name) return -1;
          if (a.name > b.name) return 1;

          return 0;
        }

        var sections = this.$store.getters.sections;
        return sections.sort(regionRiverSection);
      },
      isAdded() {
        if (this.selectedSection.alias) {
          return true;
        } else {
          return false;
        }
      },
    },
    watch: {
      selectedSection: {
        deep: true,
        handler() {
          this.reloadData = true;
        },
      },
    },
  };
</script>

<style lang="scss" scoped>
  .scrollable {
    max-height: 81vh;
    overflow: auto;
  }
  .sticky-top {
    position: sticky;
    top: 64px;
  }
  .small-title {
    font-size: 0.85rem;
  }
</style>
