<template>
  <div>
    <v-fab-transition v-if="isModification">
      <v-btn v-hotkey="'ctrl+s'" color="green" fab dark fixed bottom right @click="save">
        <v-icon large>mdi-content-save-outline</v-icon>
      </v-btn>
    </v-fab-transition>
    <v-snackbar v-model="isSaved" color="success">
      La section a été sauvegardée avec succès.

      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="isSaved = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
    <v-form ref="sectionFlow" v-model="valid">
      <v-container>
        <v-row>
          <v-col>
            <v-alert v-if="errorMessage" dense text border="left" type="error" elevation="2">
              {{ errorMessage }}
            </v-alert>
            <v-alert v-if="isModification" dense text border="left" type="warning" elevation="2">
              Attention des modifications semblent en cours dans cet onglet. Veuillez vous assurer de sauvegarder avant
              de changer de section ou d'onglet. (Appuyer sur CTRL+S pour sauvegarder)
            </v-alert>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6">
            <v-text-field v-model="reloadData" v-show="false"></v-text-field>
            <p v-if="extrapolation.gauge_id">gauge id : {{ extrapolation.gauge_id }}</p>
            <p v-if="!extrapolation.gauge_id">
              gauge id : <span class="warning--text">Aucune extrapolation pour cette section</span>
            </p>
            <p>type : {{ extrapolation.type }}</p>
            <v-combobox
              v-model="extrapolation.gauge_station_name"
              :items="gauges"
              item-text="name"
              item-value="name"
              label="Station"
              @change="updateExtrapolationGaugeInfo($event)"
              required
            ></v-combobox>
            <v-text-field
              type="number"
              label="Extrapolation montante"
              v-model="extrapolation.weighting_up"
              required
            ></v-text-field>
            <v-text-field
              type="number"
              label="Extrapolation descendante"
              v-model="extrapolation.weighting_down"
              required
            ></v-text-field>
          </v-col>
          <v-col cols="6"> </v-col>
        </v-row>
      </v-container>
    </v-form>
  </div>
</template>

<script>
  import { SectionsService } from "@/store/modules/sections";
  export default {
    props: {
      section: {
        type: Object,
        default: function() {
          return {};
        },
      },
      reloadData: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        isSaved: false,
        isModification: false,
        errorMessage: "",
        valid: true,
        extrapolation: {},
      };
    },
    async created() {
      await this.getGauges();
      this.getExtrapolation();
    },
    async updated() {
      if (this.reloadData) {
        this.getExtrapolation();
        this.$emit("update", false);
      }
    },
    methods: {
      async getExtrapolation() {
        const extrapolations = await SectionsService.actions.getExtrapolationBySectionId(this.section.id);
        if (extrapolations) {
          this.extrapolation = extrapolations[0];
        } else {
          this.extrapolation = {
            type: "CEHQ",
          };
        }
      },
      async getGauges() {
        await this.$store.dispatch("bindGauges");
      },
      updateExtrapolationGaugeInfo(event) {
        this.extrapolation.gauge_station_name = event.name;
        this.extrapolation.gauge_id = event.id;
        this.extrapolation.type = event.type;
      },
      async save() {
        if (this.valid) {
          const gaugeExist = this.lookIfGaugeExist();
          console.log("gaugeExist is " + gaugeExist); // TODO add river (create alias)

          SectionsService.actions
            .setExtrapolation(this.section.id, this.extrapolation)
            .then(() => {
              this.isModification = false;
              this.isSaved = true;
              this.successMessage;
            })
            .catch((error) => {
              console.log(error);
              this.errorMessage =
                "Une erreur s'est produite lors de la sauvegarde de l'extrapolation', vérifiez les résultats et recommencez.";
            });
        } else {
          this.errorMessage = "Certains champs obligatoires ne répondent pas aux critères attendus, veuillez vérifier.";
        }
      },
      lookIfGaugeExist() {
        const gauges = this.$store.getters.gauges;
        var exist = false;
        gauges.forEach((gauge) => {
          if (gauge.name.toLowerCase() === this.extrapolation.gauge_station_name.toLowerCase()) {
            exist = true;
          }
        });
        return exist;
      },
    },
    computed: {
      gauges() {
        return this.$store.getters.gauges;
      },
    },
    watch: {
      extrapolation: {
        deep: true,
        handler(oldValue, newValue) {
          this.isSaved = false;
          this.errorMessage = "";
          if (oldValue.id !== newValue.id) {
            this.isModification = false;
          } else {
            this.isModification = true;
          }
        },
      },
    },
  };
</script>

<style lang="scss" scoped></style>
