//import regionsDB from "@/store/db/qww_region.db";
import { firestoreAction } from "vuexfire";
import { db } from "@/firebase";

const gaugesRef = db.collection("gauges");

export const GaugesService = {
  state: {
    gauges: [],
  },
  getters: {
    gauges: (state) => {
      return state.gauges;
    },
  },
  actions: {
    bindGauges: firestoreAction(({ bindFirestoreRef }) => {
      // return the promise returned by `bindFirestoreRef`
      return bindFirestoreRef("gauges", gaugesRef.orderBy("name"));
    }),
    async getGauge(id) {
      console.log("getGauge_id", id);
      const snapshot = await gaugesRef.doc(id).get();
      if (!snapshot.exists) {
        console.log("No such document!");
        return;
      }
      return snapshot.data();
    },
    async findGaugesByName(gaugeName) {
      const snapshot = await gaugesRef.where("name", "==", gaugeName).get();
      if (snapshot.empty) {
        console.log("No matching documents.");
        return;
      }
      return snapshot;
    },
    async addGauge(gauge) {
      const result = await gaugesRef.add(gauge);
      return result.id;
    },
    async updateGauge(gauge) {
      console.log("updateGaugeNewInfo", gauge);
      const gaugeRefUpdate = gaugesRef.doc(gauge.id);
      if (!gaugeRefUpdate) {
        console.log("No such document!");
        return;
      }
      await gaugeRefUpdate.update(gauge);
    },
    async deleteGauge(gaugeId) {
      const gaugeRefDelete = gaugesRef.doc(gaugeId);
      await gaugeRefDelete.delete();
    },
  },
};
