<template>
  <div class="gauge-edit">
    <v-toolbar color="primary darken-1" dark>
      <v-icon class="mr-8" @click="$router.push({ name: 'admin-gauge-list' })" title="Retour à la liste des stations">
        mdi-arrow-left
      </v-icon>
      <v-toolbar-title class="subtitle-1" v-if="screen === 'edit'">
        Modifier une station <small>(Administration)</small>
      </v-toolbar-title>
      <v-toolbar-title class="subtitle-1" v-else> Ajouter une station <small>(Administration)</small> </v-toolbar-title>
    </v-toolbar>
    <v-btn
      class="mt-n7"
      dark
      right
      absolute
      fab
      color="error"
      @click="showDeleteConfirmation = true"
      v-if="screen === 'edit'"
    >
      <v-icon dark> mdi-delete </v-icon>
    </v-btn>
    <!-- Confirmation Dialog -->
    <v-dialog v-model="showDeleteConfirmation" persistent max-width="600px">
      <v-card>
        <v-card-title class="headline">Confirmer la suppression</v-card-title>
        <v-card-text> Êtes-vous sûr de vouloir supprimer cette station ? Cette action est irréversible. </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="showDeleteConfirmation = false">Annuler</v-btn>
          <v-btn color="red darken-1" text @click="confirmDelete">Confirmer</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Form for editing gauge details -->
    <v-form>
      <v-container>
        <h2>Information sur la station</h2>
        <v-row class="mb-3" no-gutters>
          <v-col md="6">
            <v-text-field v-model="gauge.name" label="Nom" required></v-text-field>
            <v-text-field v-model="gauge.description" label="Description" required></v-text-field>
            <v-select v-model="gauge.type" label="Type" :items="gaugeTypes" required></v-select>
            <v-switch v-model="gauge.active" label="Actif" color="green darken-3"></v-switch>
            <v-btn depressed color="primary" @click="updateGauge(gauge)" v-if="screen === 'edit'">
              <v-icon>mdi-pencil</v-icon>
              &nbsp; Modifier les informations de la station
            </v-btn>
            <v-btn depressed color="primary" @click="addGauge(gauge)" v-if="screen === 'add'">
              <v-icon>mdi-plus</v-icon>
              &nbsp; Ajouter la station
            </v-btn>
          </v-col>
          <v-col md="6"> </v-col>
        </v-row>
      </v-container>
    </v-form>
    <v-divider></v-divider>
    <v-container>
      <h2>Information sur le niveau d'eau</h2>
      <v-btn depressed color="primary" @click="viewWaterLevelHistory"
        ><v-icon>mdi-gauge</v-icon> Voir l'historique des niveaux d'eau</v-btn
      >
      <v-row class="mb-3" no-gutters>
        <v-col md="6"> {{ waterFlow }} </v-col>
        <v-col md="6"> </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
  import { GaugesService } from "../../../store/modules/gauges"; // Import the GaugesService from the store
  import { functions } from "@/firebase";
  export default {
    name: "GaugeEdit",
    data() {
      return {
        screen: "edit",
        gaugeId: this.$route.params.slug,
        gauge: { id: "", name: "", description: "", type: "", active: false },
        gaugeTypes: ["CEHQ", "Hydro-Quebec (Pas dispo encore)"], // Add the gauge types here
        showDeleteConfirmation: false,
        waterFlow: [],
      };
    },
    created() {
      if (this.gaugeId !== "new" && this.gaugeId !== undefined) {
        this.loadGaugeInfo();
      } else {
        this.gauge = { id: "", name: "", description: "", type: "", active: true };
        this.screen = "add";
      }
    },
    methods: {
      /*
      TODO
      Add a section to view gauge water level history

      */
      async loadGaugeInfo() {
        console.log("Loading gauge info for ID:", this.gaugeId);

        this.gauge = await this.getGaugeInfo();
        this.gauge.id = this.gaugeId;
        console.log("Gauge loaded:", this.gauge);
      },
      async getGaugeInfo() {
        return await GaugesService.actions.getGauge(this.gaugeId);
      },
      async addGauge(gauge) {
        await GaugesService.actions.addGauge(gauge);
        this.redirectToGaugeList();
      },
      async updateGauge(gauge) {
        await GaugesService.actions.updateGauge(gauge);
        this.redirectToGaugeList();
      },
      confirmDelete() {
        this.deleteGauge(this.gaugeId);
        this.showDeleteConfirmation = false; // Close the dialog after confirmation
        this.redirectToGaugeList();
      },
      async deleteGauge(gaugeId) {
        await GaugesService.actions.deleteGauge(gaugeId);
      },
      redirectToGaugeList() {
        this.$router.push({ name: "admin-gauge-list" });
      },
      async viewWaterLevelHistory() {
        var viewWaterFlow = functions.httpsCallable("viewWaterFlow");
        const waterFlow = await viewWaterFlow({ gaugeId: this.gaugeId })
          .then((result) => {
            return result;
          })
          .catch((error) => {
            return error;
          });

        this.waterFlow = waterFlow;
      },
    },
  };
</script>

<style scoped>
  .gauge-edit {
    /* Styles for the gauge edit component */
  }
</style>
