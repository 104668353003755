<template>
  <div><Highcharts :options="chartOptions"></Highcharts></div>
</template>

<script>
  import { Chart } from "highcharts-vue";
  import Highcharts from "highcharts";
  import exportingInit from "highcharts/modules/exporting";

  exportingInit(Highcharts);

  Highcharts.setOptions({
    lang: {
      shortMonths: ["janv", "févr", "mars", "avril", "mai", "juin", "juill", "août", "sept", "oct", "nov", "déc"],
      weekdays: ["Dimanche", "Lundi", "Mardi", "Mercredi", "Jeudi", "Vendredi", "Samedi"],
    },
  });

  export default {
    components: {
      Highcharts: Chart,
    },
    props: {
      minSugLevel: {
        type: Number,
      },
      maxSugLevel: {
        type: Number,
      },
      levelSectionHistory: {
        type: Array,
      },
    },
    data() {
      return {
        chartOptions: {
          chart: {
            type: "spline",
          },
          title: {
            text: null,
          },
          xAxis: {
            type: "datetime",
            title: {
              text: "Journée",
            },
            dateTimeLabelFormats: {
              minute: "%H:%M",
              hour: "%H:%M",
              day: "%e. %b",
              month: "%b '%y",
            },
            plotBands: [
              {
                from: "",
                to: "",
                color: "rgba(68, 170, 213, .2)",
              },
            ],
            crosshair: true,
          },
          tooltip: {
            valueSuffix: "m&sup3;/h",
          },
          yAxis: {
            softMax: this.maxSugLevel,
            softMin: this.minSugLevel,
            title: {
              text: "m3/s",
            },
            plotBands: [
              {
                label: "Niveaux bas",
                from: this.minSugLevel,
                to: 0,
                color: "#faa8a2",
              },
              {
                label: "Niveaux recommandés",
                from: this.minSugLevel,
                to: this.maxSugLevel,
                color: "#8cdb8f",
              },
              {
                label: "Niveaux haut",
                from: this.maxSugLevel,
                to: 999999999,
                color: "#b0d8f7",
              },
            ],
          },
          series: [
            {
              name: "Débit/heure",
              color: "#1565C0",
              data: [], // sample data
            },
          ],
          exporting: {
            enabled: false,
          },
        },
      };
    },
    methods: {
      getData() {
        let chartData = [];
        this.levelSectionHistory.forEach((dataHistory) => {
          chartData.unshift([
            Date.parse(dataHistory.date.replace(/-/g, "/") + " " + dataHistory.hour + " GMT"),
            Number(dataHistory.flow),
          ]);
        });
        this.chartOptions.series[0].data = chartData;
        var today = new Date();
        var todayFromLimit = Date.parse(
          today.getFullYear() +
            "-" +
            (today.getMonth() + 1 < 10 ? "0" : "") +
            (today.getMonth() + 1) +
            "-" +
            (today.getDate() + 1 < 10 ? "0" : "") +
            today.getDate() +
            " 00:00:00 GMT"
        );
        this.chartOptions.xAxis.from = todayFromLimit;

        var todayToLimit = Date.parse(
          today.getFullYear() +
            "-" +
            (today.getMonth() + 1 < 10 ? "0" : "") +
            (today.getMonth() + 1) +
            "-" +
            (today.getDate() + 1 < 10 ? "0" : "") +
            today.getDate() +
            " 23:59:59 GMT"
        );
        this.chartOptions.xAxis.to = todayToLimit;
      },
    },
    created() {
      this.getData();
    },
  };
</script>

<style lang="scss" scoped></style>
