<template>
  <div>
    <v-container>
      <v-row justify="space-around">
        <v-col cols="12" md="4" sm="4">
          <v-card height="100%" hover @click="showImage(sectionTopo)">
            <v-img
              :src="sectionTopo.HrefLink"
              class="white--text align-end"
              gradient="to top, rgba(0,0,0,.6), rgba(0,0,0,0)"
              max-height="300"
              position="center"
            >
            </v-img>
          </v-card>
        </v-col>
      </v-row>
      <v-overlay :absolute="absolute" :value="overlay">
        <div @click="overlay = false">
          <v-img :max-width="windowWidth" :src="currentPhoto.HrefLink" contain> </v-img>
        </div>
      </v-overlay>
    </v-container>
  </div>
</template>

<script>
  import { SectionsService } from "@/store/modules/sections";
  export default {
    props: {
      section: {
        type: Object,
        default: function() {
          return {};
        },
      },
    },
    data() {
      return {
        sectionTopo: {},
        currentPhoto: {},
        overlay: false,
        absolute: false,
        windowWidth: window.innerWidth,
      };
    },
    async mounted() {
      if (this.section.topo_info) {
        await this.getSectionTopo(this.section.id);
      }

      this.$nextTick(() => {
        window.addEventListener("resize", this.onResize);
      });
    },
    beforeDestroy() {
      window.removeEventListener("resize", this.onResize);
    },
    methods: {
      async getSectionTopo(id) {
        this.sectionTopo = await SectionsService.actions.findSectionsTopos(id);
      },
      showImage(photo) {
        this.currentPhoto = photo;
        this.overlay = true;
      },
      onResize() {
        this.windowWidth = window.innerWidth;
      },
    },
  };
</script>

<style lang="scss" scoped></style>
