//import riversDB from "@/store/db/qww_river.db";

import { firestoreAction } from "vuexfire";
import { db } from "@/firebase";
import firebase from "firebase/app";
import "firebase/storage";

const sectionsRef = db.collection("sections");
const sectionsLevelHistoryRef = db.collection("sectionsLevelHistory");
const sectionsVideosRef = db.collection("sectionsVideos");
const sectionsPhotosRef = db.collection("sectionsPhotos");
const sectionsToposRef = db.collection("sectionsTopo");
const sectionsDirectionsRef = db.collection("sectionsDirections");

export const SectionsService = {
  state: {
    sections: [],
  },
  getters: {
    sections: (state) => {
      return state.sections;
    },
  },
  actions: {
    bindSections: firestoreAction((context) => {
      context.bindFirestoreRef("sections", sectionsRef.orderBy("alias").where("published", "==", true));
    }),
    bindSectionsAdmin: firestoreAction((context) => {
      context.bindFirestoreRef("sections", sectionsRef.orderBy("alias"));
    }),
    async querySectionsByFilters(filters) {
      var query = sectionsRef.orderBy("alias");
      if (filters) {
        if (filters.regions.length > 0) {
          query = query.where("region", "in", filters.regions);
        }

        if (filters.classes.length > 0) {
          query = query.where("class_level", "in", filters.classes);
        }

        if (filters.levels.length > 0) {
          query = query.where("level_status", "in", filters.levels);
        }
      }

      const snapshot = await query.get();

      if (snapshot.empty) {
        return;
      }

      var sectionData = snapshot.docs.map((doc) => {
        return { ...doc.data(), id: doc.id };
      });

      return sectionData;
    },
    async findSectionsByAlias(sectionAlias) {
      const snapshot = await sectionsRef.where("alias", "==", sectionAlias).get();
      if (snapshot.empty) {
        return;
      }

      return snapshot;
    },
    async getExtrapolationBySectionId(id) {
      const extrapolationDoc = await sectionsRef
        .doc(id)
        .collection("extrapolation")
        .get();

      if (extrapolationDoc.empty) {
        return;
      }

      var extrapolationData = extrapolationDoc.docs.map((doc) => {
        return { ...doc.data(), id: doc.id };
      });

      return extrapolationData;
    },
    async setExtrapolation(sectionId, extrapolation) {
      sectionsRef
        .doc(sectionId)
        .collection("extrapolation")
        .doc(extrapolation.id)
        .set(
          {
            gauge_id: extrapolation.gauge_id,
            gauge_station_name: extrapolation.gauge_station_name,
            type: extrapolation.type,
            weighting_up: extrapolation.weighting_up,
            weighting_down: extrapolation.weighting_down,
          },
          { merge: true }
        )
        .catch((error) => {
          console.log(error);
        });
    },
    async findSectionFlowHistory(id) {
      const sectionsLevelHistoryDoc = sectionsLevelHistoryRef.doc(id);
      const doc = await sectionsLevelHistoryDoc.get();

      if (!doc.exists) {
        return null;
      }

      return doc;
    },
    async deleteSectionFlowHistory(sectionId) {
      const sectionLevelHistoryRefDelete = sectionsLevelHistoryRef.doc(sectionId);
      await sectionLevelHistoryRefDelete.delete();
    },
    async findSectionVideos(id) {
      const sectionsVideosDoc = sectionsVideosRef.doc(id);
      const doc = await sectionsVideosDoc.get();

      if (!doc.exists) {
        return null;
      }

      return doc.data();
    },
    async setSectionVideos(sectionId, videos) {
      const sectionsVideosDoc = sectionsVideosRef.doc(sectionId);

      if (videos) {
        sectionsVideosDoc.set({
          links: JSON.stringify(videos),
        });
      }
    },
    async deleteSectionVideos(sectionId) {
      const sectionVideosRefDelete = sectionsVideosRef.doc(sectionId);
      await sectionVideosRefDelete.delete();
    },
    async findSectionPhotos(id) {
      const sectionsPhotosDoc = sectionsPhotosRef.doc(id);
      const doc = await sectionsPhotosDoc.get();

      if (!doc.exists) {
        return null;
      }

      return doc.data();
    },
    async setSectionsPhotos(sectionId, photos) {
      const sectionsPhotosDoc = sectionsPhotosRef.doc(sectionId);

      if (photos) {
        sectionsPhotosDoc.set({
          links: JSON.stringify(photos),
        });
      }
    },
    async deletePhotoFolder(sectionId) {
      const storageRef = firebase.storage().ref(sectionId);
      storageRef.listAll().then((listResults) => {
        const promises = listResults.items.map((item) => {
          return item.delete();
        });
        Promise.all(promises);
      });
    },
    async deleteSectionsPhotos(sectionId) {
      const sectionPhotoRefDelete = sectionsPhotosRef.doc(sectionId);
      await sectionPhotoRefDelete.delete();
    },
    async findSectionsTopos(id) {
      const sectionsToposDoc = sectionsToposRef.doc(id);
      const doc = await sectionsToposDoc.get();

      if (!doc.exists) {
        return {
          HrefLink: "",
          links: "",
          type: "",
        };
      }

      return doc.data();
    },
    async setSectionsTopos(sectionId, topo) {
      const sectionsToposDoc = sectionsToposRef.doc(sectionId);
      sectionsToposDoc.set(
        {
          HrefLink: topo.HrefLink,
          rapids: topo.rapids ? JSON.stringify(topo.rapids) : "",
          type: topo.type,
        },
        { merge: true }
      );
    },
    async deleteSectionTopos(sectionId) {
      const sectionToposRefDelete = sectionsToposRef.doc(sectionId);
      await sectionToposRefDelete.delete();
    },
    async findSectionsDirections(id) {
      const sectionsDirectionsDoc = sectionsDirectionsRef.doc(id);
      const doc = await sectionsDirectionsDoc.get();

      if (!doc.exists) {
        return {
          markers: "[]",
          sectionPath: "[]",
        };
      }

      return doc.data();
    },
    async setSectionsDirections(sectionId, directions) {
      const sectionsDirectionsDoc = sectionsDirectionsRef.doc(sectionId);
      sectionsDirectionsDoc.set(
        {
          markers: directions.markers,
          sectionPath: directions.sectionPath,
        },
        { merge: true }
      );
    },
    async deleteSectionsDirections(sectionId) {
      const sectionDirectionsRefDelete = sectionsDirectionsRef.doc(sectionId);
      await sectionDirectionsRefDelete.delete();
    },
    async addSection(section) {
      section.alias = this.createAlias(section.name);
      const result = await sectionsRef.add(section);
      console.log(result.id);
      return result.id;
    },
    async deleteSection(section) {
      await this.deletePhotoFolder(section.id);
      await this.deleteSectionsPhotos(section.id);
      await this.deleteSectionsDirections(section.id);
      await this.deleteSectionFlowHistory(section.id);
      await this.deleteSectionTopos(section.id);
      await this.deleteSectionVideos(section.id);

      const sectionRefDelete = sectionsRef.doc(section.id);
      await sectionRefDelete.delete();
    },
    async updateSection(section) {
      section.alias = this.createAlias(section.river + " " + section.name);
      const sectionRefUpdate = sectionsRef.doc(section.id);
      await sectionRefUpdate.update(section);
    },
    createAlias(stringToAlias) {
      stringToAlias = stringToAlias.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
      return stringToAlias
        .replace(/[^a-z0-9\s]/gi, "")
        .trim()
        .replace(/\s+/g, "-")
        .toLowerCase();
    },
  },
};
