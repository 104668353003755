<template>
  <div>
    <h1 class="text-h5 text-md-h3 text-lg-h2 text-xl-h2 text-truncate">
      Tableau de bord de gestion
    </h1>
  </div>
</template>

<script>
  export default {};
</script>

<style lang="scss" scoped></style>
