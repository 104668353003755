<template>
  <div>
    <h2 class="text-h5">
      Bienvenue <span v-if="user">, {{ user.displayName }}</span>
    </h2>
    <v-row>
      <v-col cols="12"></v-col>
      <v-col cols="12">
        <v-card class="mt-4 ml-2 mr-2" elevation="6" v-if="isConnected">
          <v-card-title>
            <h3 class="text-h5">Mon compte</h3>
          </v-card-title>
          <v-card-text>
            <subscribedAccount
              :subscription="subscription"
              :isMember="isMember"
              :membershipEndDate="membershipEndDate"
              :isAdmin="isAdmin"
            ></subscribedAccount>
          </v-card-text>
        </v-card>
        <v-card class="mt-4 ml-2 mr-2" elevation="6">
          <v-card-title>
            <h3 class="text-h5">Nos partenaires</h3>
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="12" md="6" lg="4" v-for="partner in partners" :key="partner.id">
                <v-card class="mx-auto" max-width="600" outlined>
                  <v-img :src="partner.image" height="200px"></v-img>
                  <v-card-title>{{ partner.name }}</v-card-title>
                  <v-card-text>{{ partner.description }}</v-card-text>
                  <v-card-actions>
                    <v-btn :href="partner.link" target="_blank" text color="primary">Visiter</v-btn>
                  </v-card-actions>
                </v-card>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row> </v-row>
  </div>
</template>

<script>
  import { auth } from "@/firebase";
  import { UserService } from "@/store/modules/user";
  import SubscribedAccount from "@/components/pages/subscription/SubscribedAccount.vue";
  export default {
    components: { SubscribedAccount },
    props: {
      isMember: Boolean,
      membershipEndDate: Number,
      isAdmin: Boolean,
      isConnected: Boolean,
    },
    data() {
      return {
        loading: false,
        user: null,
        subscription: [],
        partners: [
          {
            id: 1,
            name: "Partenaire 1",
            description: "Description du partenaire 1",
            image: "https://picsum.photos/200/300",
            link: "https://www.google.com",
          },
          {
            id: 2,
            name: "Partenaire 2",
            description: "Description du partenaire 2",
            image: "https://picsum.photos/200/300",
            link: "https://www.google.com",
          },
          {
            id: 3,
            name: "Partenaire 3",
            description: "Description du partenaire 3",
            image: "https://picsum.photos/200/300",
            link: "https://www.google.com",
          },
          {
            id: 4,
            name: "Partenaire 4",
            description: "Description du partenaire 4",
            image: "https://picsum.photos/200/300",
            link: "https://www.google.com",
          },
          {
            id: 5,
            name: "Partenaire 5",
            description: "Description du partenaire 5",
            image: "https://picsum.photos/200/300",
            link: "https://www.google.com",
          },
          {
            id: 6,
            name: "Partenaire 6",
            description: "Description du partenaire 6",
            image: "https://picsum.photos/200/300",
            link: "https://www.google.com",
          },
        ],
      };
    },
    async mounted() {
      this.loading = true;
      auth.onAuthStateChanged((user) => {
        if (user) {
          this.user = user;
        } else {
          this.user = null;
        }
      });
      this.subscription = await UserService.actions.fetchCustomerSubscriptions();
      this.loading = false;
    },
  };
</script>

<style lang="scss" scoped></style>
