import { StringService } from "@/services/String.service";

export const GmapsService = {
  BASE_CENTER_POSITION: { lat: 46.46, lng: -72.7 },
  LABEL_PUTIN: {
    text: "Put In",
    className: "text-overline font-weight-bold",
    color: "black",
  },
  SVG_PUTIN: {
    path:
      "M22 23V21C20.58 21.05 19.21 20.9 18 20C16.23 21.25 13.77 21.25 12 20C10.23 21.25 7.77 21.25 6 20C4.79 20.9 3.42 21.05 2 21V23C3.38 23.05 4.79 22.94 6 22.25C7.84 23.25 10.16 23.25 12 22.25C13.84 23.25 16.16 23.25 18 22.25C19.21 22.94 20.62 23.05 22 23M23.39 17.21C21.13 16.29 18.3 15.56 15.66 15.22L19.36 6.88L20.67 6.41L22 3.41L18.8 2L17.5 4.95L18 6.27L16.31 9.97L13.5 11.47L11 10.2C10.95 10.14 10.86 10.09 10.74 10.06C9.82 9.73 8.77 10.32 8.5 11.23L7.13 15.41C6.59 15.18 1.25 16.97 .915 17.12L0 17.47C1.33 18.04 2.2 18.39 3.94 18.88C4.75 18.63 5.44 18.09 6 17.47C7.5 19.42 10.5 19.42 12 17.47C13.5 19.42 16.5 19.42 18 17.47C18.56 18.09 19.25 18.63 20.06 18.88L22.95 17.91L24 17.47L23.39 17.21M14.06 15.08C13.07 15 12.06 15 11.06 15L11.77 12.83L13.5 13.77L15 12.92L14.06 15.08M14 7.5C14 8.61 13.11 9.5 12 9.5S10 8.61 10 7.5 10.9 5.5 12 5.5 14 6.4 14 7.5Z",
    fillColor: "black",
    fillOpacity: 1,
    strokeWeight: 0.6,
    strokeColor: "white",
    rotation: 0,
    scale: 1.75,
  },
  LABEL_TAKEOUT: {
    text: "Take Out",
    className: "text-overline font-weight-bold",
    color: "black",
  },
  SVG_TAKEOUT: {
    path:
      "M9.5,0A3,3 0 0,0 6.5,3A3,3 0 0,0 9.5,6C10.81,6 11.92,5.17 12.33,4H14.5V6H16.5V4H17.5V2H12.33C11.92,0.83 10.81,0 9.5,0M9.5,2A1,1 0 0,1 10.5,3A1,1 0 0,1 9.5,4A1,1 0 0,1 8.5,3A1,1 0 0,1 9.5,2M6.5,8C5.84,8 5.28,8.42 5.08,9L3,15V23A1,1 0 0,0 4,24H5A1,1 0 0,0 6,23V22H18V23A1,1 0 0,0 19,24H20A1,1 0 0,0 21,23V15L18.92,9C18.72,8.42 18.16,8 17.5,8H6.5M6.5,9.5H17.5L19,14H5L6.5,9.5M6.5,16A1.5,1.5 0 0,1 8,17.5A1.5,1.5 0 0,1 6.5,19A1.5,1.5 0 0,1 5,17.5A1.5,1.5 0 0,1 6.5,16M17.5,16A1.5,1.5 0 0,1 19,17.5A1.5,1.5 0 0,1 17.5,19A1.5,1.5 0 0,1 16,17.5A1.5,1.5 0 0,1 17.5,16Z",
    fillColor: "black",
    fillOpacity: 1,
    strokeWeight: 0.6,
    strokeColor: "white",
    rotation: 0,
    scale: 1.75,
  },
  getCenterPosition(positionA, positionB) {
    let arrPositionA = [];
    let arrPositionB = [];

    if (StringService.isEmpty(positionA) && StringService.isEmpty(positionB)) return this.BASE_CENTER_POSITION;

    if (StringService.isEmpty(positionA)) {
      if (!StringService.isEmpty(positionB)) {
        arrPositionB = positionB.split(",");
        return { lat: Number(arrPositionB[0]), lng: Number(arrPositionB[1]) };
      }
    }
    if (StringService.isEmpty(positionB)) {
      if (!StringService.isEmpty(positionA)) {
        arrPositionA = positionA.split(",");
        return { lat: Number(arrPositionA[0]), lng: Number(arrPositionA[1]) };
      }
    }

    arrPositionA = positionA.split(",");
    arrPositionB = positionB.split(",");

    var latCenter = (Number(arrPositionA[0]) + Number(arrPositionB[0])) / 2;
    var lngCenter = (Number(arrPositionA[1]) + Number(arrPositionB[1])) / 2;
    return { lat: latCenter, lng: lngCenter };
  },
  getLatLngFromStringLatLng(latLngString) {
    let arrLatLng = latLngString.length > 0 ? latLngString.split(",") : [];

    if (arrLatLng.length == 2) {
      return { lat: Number(arrLatLng[0]), lng: Number(arrLatLng[1]) };
    } else {
      return null;
    }
  },
  getLatLngFromStringLngLat(lngLatString) {
    let arrLngLat = lngLatString.length > 0 ? lngLatString.split(",") : [];

    if (arrLngLat.length == 2) {
      return { lat: Number(arrLngLat[1]), lng: Number(arrLngLat[0]) };
    } else {
      return null;
    }
  },

  /**
   * Calculate the center/average of multiple GeoLocation coordinates
   * Expects an array of objects with .latitude and .longitude properties
   *
   * @url http://stackoverflow.com/a/14231286/538646
   */
  averageGeolocation(coords) {
    if (coords.length === 1) {
      return coords[0].options.position;
    }

    let x = 0.0;
    let y = 0.0;
    let z = 0.0;

    for (let coord of coords) {
      let latitude = (coord.options.position.lat * Math.PI) / 180;
      let longitude = (coord.options.position.lng * Math.PI) / 180;

      x += Math.cos(latitude) * Math.cos(longitude);
      y += Math.cos(latitude) * Math.sin(longitude);
      z += Math.sin(latitude);
    }

    let total = coords.length;

    x = x / total;
    y = y / total;
    z = z / total;

    let centralLongitude = Math.atan2(y, x);
    let centralSquareRoot = Math.sqrt(x * x + y * y);
    let centralLatitude = Math.atan2(z, centralSquareRoot);

    return {
      lat: (centralLatitude * 180) / Math.PI,
      lng: (centralLongitude * 180) / Math.PI,
    };
  },
  convertKmlCoordinates(stringOfCoordinates) {
    if (stringOfCoordinates.length == 0) return null;

    const arrCoordinates = stringOfCoordinates.split(",0 ");
    let arrLatLng = [];

    arrCoordinates.forEach((coordinates) => {
      if (coordinates.length > 0) {
        var latLng = this.getLatLngFromStringLngLat(coordinates);

        if (latLng) arrLatLng.push(latLng);
      }
    });
    return arrLatLng;
  },
};
