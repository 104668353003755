export const StringService = {
  isEmpty(stringToTest) {
    if (stringToTest.length == 0) {
      return true;
    } else {
      return false;
    }
  },
  formatAmount(plainAmount) {
    var amount = plainAmount / 100;
    return amount.toFixed(2);
  },
  numberFormatter(num, digits) {
    const lookup = [
      { value: 1, symbol: "" },
      { value: 1e3, symbol: "k" },
      { value: 1e6, symbol: "M" },
      { value: 1e9, symbol: "G" },
      { value: 1e12, symbol: "T" },
      { value: 1e15, symbol: "P" },
      { value: 1e18, symbol: "E" },
    ];
    const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
    var item = lookup
      .slice()
      .reverse()
      .find(function(item) {
        return num >= item.value;
      });
    return item ? (num / item.value).toFixed(digits).replace(rx, "$1") + item.symbol : "0";
  },
  getLastUpdateDayAndMonth(timestamp_to_format) {
    var lastUpdateDayAndMonth = "";
    if (timestamp_to_format) {
      timestamp_to_format = timestamp_to_format.replace(/-/g, "/");
      var dateLastUpdate = new Date(timestamp_to_format);

      lastUpdateDayAndMonth = dateLastUpdate.getDay() + " " + this.getMonthAbbr(dateLastUpdate.getMonth() + 1);

      lastUpdateDayAndMonth;
    }

    return lastUpdateDayAndMonth;
  },
  getMonthAbbr(month) {
    switch (month) {
      case 1:
        return "janvier";
      case 2:
        return "février";
      case 3:
        return "mars";
      case 4:
        return "avril";
      case 5:
        return "mai";
      case 6:
        return "juin";
      case 7:
        return "juillet";
      case 8:
        return "août";
      case 9:
        return "septembre";
      case 10:
        return "octobre";
      case 11:
        return "novembre";
      case 12:
        return "décembre";

      default:
        return "";
    }
  },
};
