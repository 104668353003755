import levelsDB from "@/store/db/qww_level.db";

export default {
  state: {
    levels: [],
  },
  getters: {
    levels: (state) => {
      return state.levels;
    },
  },
  mutations: {
    setLevels(state, levels) {
      state.levels = levels;
    },
  },
  actions: {
    fetchLevels({ commit }) {
      const levels = levelsDB;
      commit("setLevels", levels);
    },
  },
};
