//import { firestoreAction } from "vuexfire";
import firebase from "firebase/app";
import "firebase/storage";

export const UploadService = {
  actions: {
    async uploadImage(baseUrl, imageData) {
      return new Promise((resolve, _reject) => {
        firebase
          .storage()
          .ref(`${baseUrl}/${imageData.name}`)
          .put(imageData)
          .then(async (r) => {
            r.ref
              .getDownloadURL()
              .then((url) => {
                resolve({
                  success: true,
                  url: url,
                  imageName: imageData.name,
                });
              })
              .catch((e) => {
                resolve({
                  success: false,
                  msg: e.message,
                });
              });
          })
          .catch((e) => {
            resolve({
              success: false,
              msg: e.message,
            });
          });
      });
    },
    async deleteImage(baseUrl, imageName) {
      return new Promise((resolve, _reject) => {
        firebase
          .storage()
          .ref(`${baseUrl}/${imageName}`)
          .delete()
          .then(() => {
            resolve({
              success: true,
            });
          })
          .catch((e) => {
            resolve({
              success: false,
              msg: e.message,
            });
          });
      });
    },
  },
};
