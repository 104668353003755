<template>
  <div>
    <h1 class="text-h5 text-md-h3 text-lg-h2 text-xl-h2 text-truncate">
      Importation des données dans Firebase
    </h1>
    <v-container class="pt-6">
      <v-row>
        <v-col>
          <v-btn depressed color="primary" class="mt-3 mr-3" @click="copyPhotosIntoTopos()" :disabled="isLoading"
            >Copier les Images dans Topo</v-btn
          >
          <v-progress-circular v-if="isLoadingRegion" indeterminate color="primary" class="mt-3"></v-progress-circular>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
  //import { GaugesService } from "@/store/modules/gauges";
  //import { RegionsService } from "@/store/modules/regions";
  //import { RiversService } from "@/store/modules/rivers";
  //import { SectionsService } from "@/store/modules/sections";
  //import qww_region from "@/store/db/qww_region";
  //import qww_rivers from "@/store/db/qww_rivers";
  //import qww_sections from "@/store/db/qww_sections";
  //import qww_extrapolation from "@/store/db/qww_extrapolation.db";
  //import qww_gauges from "@/store/db/qww_gauge_list.db";
  //import qww_sections_topo_direction_videos from "@/store/db/qww_sections_topo_direction_videos.db";
  import { /*auth,*/ functions } from "@/firebase";
  //import { db } from "@/firebase";
  //import { mapGetters, mapState } from "vuex";

  export default {
    data() {
      return {
        isLoading: false,
        isLoadingRegion: false,
        isLoadingRiver: false,
        isLoadingSection: false,
        isLoadingGauge: false,
        gauges: [],
        gaugesDB: [],
        gauge_data: "",
        responseResult: "",
        publicPath: process.env.BASE_URL,
      };
    },
    async created() {},
    async mounted() {},
    methods: {
      async updateClaims() {},
      async copyPhotosIntoTopos() {
        var copyPhotosIntoTopos = functions.httpsCallable("copyCollection");
        await copyPhotosIntoTopos({ srcCollectionName: "sectionsPhotos", destCollectionName: "sectionsTopos" })
          .then((result) => {
            return result;
          })
          .catch((error) => {
            return error;
          });
      },
    },
  };
</script>

<style lang="scss" scoped></style>
