<template>
  <v-app>
    <v-app-bar app color="primary" dark>
      <v-app-bar-nav-icon @click.stop="drawer = !drawer" class="d-flex d-lg-none"></v-app-bar-nav-icon>
      <v-toolbar-title> Québec whitewater </v-toolbar-title>
      <v-spacer></v-spacer>

      <v-btn
        :to="{ name: 'accueil', props: { isMember: this.isMember, isAdmin: this.isAdmin } }"
        exact
        text
        class="d-none d-lg-flex"
        ><v-icon>mdi-home</v-icon> Accueil</v-btn
      >
      <v-btn :to="{ name: 'rivieres' }" text class="d-none d-lg-flex"><v-icon>mdi-map-search</v-icon> Rivières</v-btn>
      <v-btn :to="{ name: 'a-propos' }" text class="d-none d-lg-flex"
        ><v-icon>mdi-information-outline</v-icon> À propos</v-btn
      >
      <v-menu transition="scroll-y-transition">
        <template v-slot:activator="{ on, attrs }">
          <v-btn dark icon v-bind="attrs" v-on="on">
            <v-icon v-if="!isConnected">mdi-account-outline</v-icon>
            <v-icon v-if="isConnected">mdi-account</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item v-if="isConnected">
            <v-list-item-title><v-icon>mdi-card-account-details-outline</v-icon> Mon profil</v-list-item-title>
          </v-list-item>
          <v-list-item v-if="isConnected" :to="{ name: 'payment-portal' }">
            <v-list-item-title><v-icon>mdi-credit-card-clock-outline</v-icon> Payment Portal</v-list-item-title>
          </v-list-item>
          <v-list-item v-if="!isConnected" :to="{ name: 'connexion' }">
            <v-list-item-title><v-icon>mdi-login</v-icon> Connexion</v-list-item-title>
          </v-list-item>
          <v-list-item v-if="isConnected" @click="logout()">
            <v-list-item-title><v-icon>mdi-logout</v-icon> Déconnexion</v-list-item-title>
          </v-list-item>
          <v-btn v-if="isAdmin" @click.stop="adminDrawer = !adminDrawer" text block class="error text-left">
            <v-icon>mdi-cog-outline</v-icon> Admin
          </v-btn>
        </v-list>
      </v-menu>
    </v-app-bar>

    <v-main>
      <v-navigation-drawer v-model="drawer" absolute temporary>
        <v-list nav dense>
          <v-list-item-group v-model="group">
            <v-list-item :to="{ name: 'accueil' }" exact>
              <v-icon left> mdi-home </v-icon>
              <v-list-item-title> Accueil</v-list-item-title>
            </v-list-item>
            <v-list-item :to="{ name: 'rivieres' }">
              <v-icon left> mdi-map-search </v-icon>
              <v-list-item-title> Rivières</v-list-item-title>
            </v-list-item>
            <v-list-item :to="{ name: 'a-propos' }">
              <v-icon left> mdi-information-outline </v-icon>
              <v-list-item-title> À propos</v-list-item-title>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-navigation-drawer>
      <v-navigation-drawer v-model="adminDrawer" absolute temporary>
        <v-list nav dense>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title class="title"> Panneau d'administration </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-divider></v-divider>
          <v-list-item-group v-model="group">
            <v-list-item :to="{ name: 'admin-sections' }">
              <v-icon left> mdi-map-search </v-icon>
              <v-list-item-title> Sections</v-list-item-title>
            </v-list-item>
            <v-list-item :to="{ name: 'admin-user-list' }">
              <v-icon left> mdi-account-group </v-icon>
              <v-list-item-title> Utilisateurs</v-list-item-title>
            </v-list-item>
            <v-list-item :to="{ name: 'admin-gauge-list' }">
              <v-icon left> mdi-gauge </v-icon>
              <v-list-item-title> Jauges</v-list-item-title>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-navigation-drawer>
      <v-banner single-line :sticky="isSticky" v-if="!isConnected">
        <v-icon slot="icon" color="primary" size="24"> mdi-account-outline </v-icon>
        Vous n'êtes pas connecté
        <template v-slot:actions>
          <v-btn color="primary" text :to="{ name: 'connexion' }"> Connexion </v-btn>
        </template>
      </v-banner>
      <subscription-banner v-if="isConnected && !isMember && !isAdmin"></subscription-banner>
      <router-view
        :isMember="isMember"
        :isAdmin="isAdmin"
        :isConnected="isConnected"
        :membershipEndDate="membershipEndDate"
      ></router-view>
    </v-main>
    <v-footer light>
      <v-spacer></v-spacer>
      <small>v{{ appVersion }}</small>
    </v-footer>
  </v-app>
</template>

<script>
  import { version } from "../package";
  import { auth, functions } from "@/firebase";
  import { UserService } from "@/store/modules/user";
  import SubscriptionBanner from "@/components/pages/subscription/SubscriptionBanner.vue";
  export default {
    name: "App",
    components: {
      SubscriptionBanner,
    },
    created() {},
    data: () => ({
      appVersion: version,
      isSticky: true,
      isConnected: false,
      isMember: false,
      membershipEndDate: null,
      isAdmin: false,
      searchCriterias: { regions: [], classes: [], levels: [] },
      drawer: false,
      adminDrawer: false,
      group: null,
    }),
    async mounted() {
      auth.onAuthStateChanged(async (user) => {
        if (user) {
          this.isConnected = true;
          const userRoles = await UserService.actions.getUserRoles(user.uid);
          this.isMember = userRoles.isMember;
          this.membershipEndDate = userRoles.membershipEndDate;
          this.isAdmin = userRoles.isAdmin;
          this.searchCriterias = await UserService.actions.getUserFiltersByUid(user.uid);
          if (!this.isMember) {
            await this.syncMembership(user);
          }
        } else {
          this.isConnected = false;
          this.isMember = false;
          this.isAdmin = false;
        }
      });
    },
    methods: {
      async logout() {
        await auth.signOut();
        this.isConnected = false;
        this.isMember = false;
        this.isAdmin = false;

        if (this.$route.name != "accueil") this.$router.push({ name: "accueil" });
      },
      async syncMembership(user) {
        var stripeFindCustomerActiveTransactionsByEmail = functions.httpsCallable(
          "stripeFindCustomerActiveTransactionsByEmail"
        );

        const customerActiveTransaction = await stripeFindCustomerActiveTransactionsByEmail({
          uId: user.id,
          email: user.email,
        })
          .then((result) => {
            return result.data;
          })
          .catch((error) => {
            console.log(error);
            return { isMember: false, endDate: null };
          });

        if (customerActiveTransaction.isMember) {
          await UserService.actions.addUserRoleMember(user.uid, customerActiveTransaction.endDate);
          console.log("Membership created!");
          this.isMember = true;
        }
      },
    },
  };
</script>
