<template>
  <div>
    <v-toolbar color="primary darken-1" dark>
      <v-icon class="mr-8" @click="$router.go(-1)" title="Retour à la liste des sections">mdi-arrow-left</v-icon>
      <v-toolbar-title class="subtitle-1">
        {{ section.name }} ({{ section.class_level }}) - {{ section.river }} - {{ section.region }}
      </v-toolbar-title>
    </v-toolbar>
    <v-tabs v-model="tab" dark background-color="primary darken-1" show-arrows>
      <v-tabs-slider color="primary lighten-3"></v-tabs-slider>

      <v-tab key="CurrentFlow">
        <v-icon title="Conditions actuelles" large>mdi-chart-bell-curve</v-icon>
        <span class="ml-1 d-none d-md-flex">Conditions actuelles</span>
      </v-tab>
      <v-tab key="Description">
        <v-icon title="Description" large>mdi-information-outline</v-icon>
        <span class="ml-1 d-none d-md-flex">Description</span>
      </v-tab>
      <v-tab key="Topography" v-if="section.topo_info">
        <v-icon title="Topographie" large>mdi-map-legend</v-icon>
        <span class="ml-1 d-none d-md-flex">Topographie</span>
      </v-tab>
      <v-tab key="Photos" v-if="section.image_info">
        <v-icon title="Photos" large>mdi-image-multiple</v-icon>
        <span class="ml-1 d-none d-md-flex">Photos</span>
      </v-tab>
      <v-tab key="Videos" v-if="section.video_info">
        <v-icon title="Vidéos" large>mdi-video-image</v-icon>
        <span class="ml-1 d-none d-md-flex">Vidéos</span>
      </v-tab>
      <v-tab key="Directions" v-if="section.direction_info">
        <v-icon title="Directions" large>mdi-google-maps</v-icon>
        <span class="ml-1 d-none d-md-flex">Directions</span>
      </v-tab>
    </v-tabs>
    <v-tabs-items touchless v-model="tab">
      <v-tab-item key="CurrentFlow">
        <v-container>
          <v-row>
            <v-col>
              <v-card>
                <v-card-title>Conditions actuelles</v-card-title>
                <v-card-text>
                  <v-row>
                    <v-col>
                      <p>
                        <strong>Débit:</strong> <br />
                        <span :class="getColor(section)">{{ section.current_level }}</span
                        >m<sup>3</sup>/s
                      </p>
                    </v-col>
                    <v-col>
                      <p>
                        <strong>Débit recommandé:</strong> <br />
                        Entre {{ section.max_sug_level }}m<sup>3</sup>/s et {{ section.min_sug_level }}m<sup>3</sup>/s
                      </p>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <p>
                        <strong>Tendance:</strong> <br />
                        <v-icon v-if="Math.sign(section.current_trend) == 1" x-small color="green darken-2">
                          mdi-arrow-up
                        </v-icon>
                        <v-icon v-if="Math.sign(section.current_trend) == -1" x-small color="red">
                          mdi-arrow-down
                        </v-icon>
                        {{ Math.abs(section.current_trend) }}m<sup>3</sup>/h
                      </p>
                    </v-col>
                    <v-col>
                      <p>
                        <strong>Mise à jour il y a :</strong> <br />
                        {{ getLastUpdate() }}
                      </p>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
          <v-row>
            <v-col col="12">
              <v-card v-if="!sectionLoading">
                <v-card-title>Historique des 7 derniers jours</v-card-title>
                <v-card-text>
                  <div v-if="sectionLoading" class="text-center">
                    <v-progress-circular indeterminate color="primary"></v-progress-circular>
                  </div>
                  <div v-if="!sectionLoading && sectionLevelHistory.level">
                    <LevelHistoryChart
                      :levelSectionHistory="sectionLevelHistory.level"
                      :minSugLevel="Number(section.min_sug_level)"
                      :maxSugLevel="Number(section.max_sug_level)"
                    />
                  </div>
                  <div v-if="!sectionLevelHistory.level">
                    Il n'y a pas de donnée historique pour cette section
                  </div>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </v-tab-item>
      <v-tab-item key="Description">
        <v-container>
          <h3>Information générale</h3>
          <v-row>
            <v-col>
              <v-card height="100%">
                <v-card-text>
                  <p>
                    <strong>Région</strong> <br />
                    {{ section.region }}
                  </p>
                  <p>
                    <strong>Longueur de la section</strong> <br />
                    {{ section.length ? section.length + "km" : "" }}
                  </p>
                  <p v-if="getTotalRapids(section) > 0">
                    <strong>Nombre de rapides ({{ getTotalRapids(section) }})</strong> <br />
                    <v-chip class="ma-2" color="blue" text-color="white" small v-if="section.rapidClassR2andLess > 0">
                      R2 et -
                      <v-avatar right class="blue darken-3">
                        {{ section.rapidClassR2andLess }}
                      </v-avatar>
                    </v-chip>
                    <v-chip class="ma-2" color="blue" text-color="white" small v-if="section.rapidClassR3 > 0">
                      R3
                      <v-avatar right class="blue darken-3">
                        {{ section.rapidClassR3 }}
                      </v-avatar>
                    </v-chip>
                    <v-chip class="ma-2" color="blue" text-color="white" small v-if="section.rapidClassR4 > 0">
                      R4
                      <v-avatar right class="blue darken-3">
                        {{ section.rapidClassR4 }}
                      </v-avatar>
                    </v-chip>
                    <v-chip class="ma-2" color="blue" text-color="white" small v-if="section.rapidClassR5 > 0">
                      R5
                      <v-avatar right class="blue darken-3">
                        {{ section.rapidClassR5 }}
                      </v-avatar>
                    </v-chip>
                    <v-chip class="ma-2" color="blue" text-color="white" small v-if="section.rapidClassR5plus > 0">
                      R5+
                      <v-avatar right class="blue darken-3">
                        {{ section.rapidClassR5plus }}
                      </v-avatar>
                    </v-chip>
                  </p>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col>
              <v-card height="100%">
                <v-card-text>
                  <p>
                    <strong>Cotation</strong> <br />
                    {{ section.class_level }}
                  </p>
                  <p v-if="section.type">
                    <strong>Catégorie de rivière:</strong> <br />
                    {{ section.type }}
                  </p>
                  <p v-if="section.gradient">
                    <strong>Gradient</strong> <br />
                    {{ section.gradient }}
                  </p>
                  <p v-if="section.portage">
                    <strong>Portages</strong> <br />
                    {{ section.portage }}
                  </p>
                  <p v-if="section.shuttle">
                    <strong>Navette</strong> <br />
                    {{ section.shuttle }}
                  </p>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-card>
                <v-card-title>Description</v-card-title>
                <v-card-text><p v-html="section.description"></p></v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </v-tab-item>
      <v-tab-item key="Topography" v-if="section.topo_info">
        <Topos :section="section"></Topos>
      </v-tab-item>
      <v-tab-item key="Photos" v-if="section.image_info">
        <Photos :section="section"></Photos>
      </v-tab-item>
      <v-tab-item key="Videos" v-if="section.video_info">
        <Videos :section="section"></Videos>
      </v-tab-item>
      <v-tab-item key="Directions" v-if="section.direction_info">
        <Directions :section="section"></Directions>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
  import { SectionsService } from "@/store/modules/sections";
  import { RiversService } from "@/services/Rivers.service";
  import LevelHistoryChart from "@/components/LevelHistoryChart";
  import Directions from "@/components/pages/river/components/Directions";
  import Topos from "@/components/pages/river/components/Topos";
  import Photos from "@/components/pages/river/components/Photos";
  import Videos from "@/components/pages/river/components/Videos";
  export default {
    components: {
      LevelHistoryChart,
      Directions,
      Photos,
      Videos,
      Topos,
    },
    data() {
      return {
        tab: null,
        section: {},
        sectionLevelHistory: [],
        sectionLoading: true,
        sectionVideos: {},
        level_unknown: "unknown",
        level_low: "low",
        level_recommended: "recommended",
        level_high: "high",
      };
    },
    async mounted() {
      await this.getSectionInfo();
      await this.getSectionLevelHistory(this.section.id);
      this.sectionLoading = false;
    },
    methods: {
      async getSectionInfo() {
        const path = window.location.pathname;
        const aliasInRequest = path.replace("/riviere/", "");
        const sectionDocs = await SectionsService.actions.findSectionsByAlias(aliasInRequest);
        const sections = sectionDocs.docs.map((section) => Object.create({ ...section.data(), id: section.id }));
        this.section = sections[0];
      },
      getTotalRapids(section) {
        return RiversService.getTotalRapids(section);
      },
      getLastUpdate() {
        return RiversService.getLastUpdate(this.section.level_last_update);
      },
      async getSectionLevelHistory(id) {
        const sectionLevelHistoryDocs = await SectionsService.actions.findSectionFlowHistory(id);
        if (sectionLevelHistoryDocs) {
          const sectionLevelHistory = sectionLevelHistoryDocs.data();
          this.sectionLevelHistory = JSON.parse(sectionLevelHistory.level_history);
        }
      },
      getColor(obj) {
        if (obj.level_status === this.level_recommended) {
          return "green--text";
        }
        if (obj.level_status === this.level_high) {
          return "blue--text";
        }
        if (obj.level_status === this.level_low) {
          return "red--text";
        }
      },
    },
  };
</script>

<style lang="scss" scoped></style>
