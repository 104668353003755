import Vue from "vue";
import Embed from "v-video-embed";
import App from "./App.vue";

import "./app.scss";

import vuetify from "./plugins/vuetify";

import { TiptapVuetifyPlugin } from "tiptap-vuetify";
import "tiptap-vuetify/dist/main.css";
import "vuetify/dist/vuetify.min.css";

import x5GMaps from "x5-gmaps";
import hotkey from "@undecaf/vue-hotkey";

import router from "./router";
import { store } from "./store";

Vue.use(x5GMaps, { key: process.env.VUE_APP_GOOGLE_MAPS_KEY, libraries: ["places", "visualization"] });
Vue.use(Embed);
Vue.use(TiptapVuetifyPlugin, {
  // the next line is important! You need to provide the Vuetify Object to this place.
  vuetify, // same as "vuetify: vuetify"
  // optional, default to 'md' (default vuetify icons before v2.0.0)
  iconsGroup: "mdi",
});
Vue.use(hotkey);

Vue.config.productionTip = false;

new Vue({
  render: (h) => h(App),
  vuetify,
  store,
  router,
}).$mount("#app");
