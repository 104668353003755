<template>
  <v-container>
    <v-form ref="form" v-model="valid" @submit="submitAction" :disabled="isLoading">
      <v-toolbar color="primary darken-1" dark>
        <v-toolbar-title class="subtitle-1">
          Création de compte
        </v-toolbar-title>
      </v-toolbar>
      <v-col cols="12" sm="6">
        <v-text-field
          v-model="displayName"
          :counter="50"
          :rules="displayNameRules"
          label="Votre nom (affiché à l'accueil)"
          required
        ></v-text-field>
        <v-text-field v-model="email" :rules="emailRules" label="Courriel (identifiant)" required></v-text-field>
        <v-text-field
          v-model="password"
          :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
          :rules="passwordRules"
          label="Mot de passe"
          :type="showPassword ? 'text' : 'password'"
          name="password"
          hint="Au minimum 8 caractères"
          class="input-group--focused"
          @click:append="showPassword = !showPassword"
        ></v-text-field>
        <v-text-field
          v-model="passwordValidation"
          :append-icon="showPasswordValidation ? 'mdi-eye' : 'mdi-eye-off'"
          :rules="passwordValidationRules"
          label="Confirmation du mot de passe"
          :type="showPasswordValidation ? 'text' : 'password'"
          name="passwordValidation"
          class="input-group--focused"
          @click:append="showPasswordValidation = !showPasswordValidation"
          :success="passwordMatch()"
        ></v-text-field>
        <v-checkbox
          v-model="checkbox"
          :rules="[(v) => !!v || 'Vous devez cocher cette case pour continuer']"
          label="Je ne suis pas un robot"
          required
        ></v-checkbox>
      </v-col>
      <v-alert border="left" color="red lighten-1" dark v-if="errorMessage">
        {{ errorMessage }}
      </v-alert>

      <v-btn :disabled="!valid" color="success" class="mr-4 mt-2" type="submit">
        Créer un compte
      </v-btn>
      <v-btn color="error" class="mr-4 mt-2" @click="reset" :disabled="isLoading">
        Réinitialiser le formulaire
      </v-btn>
      <v-btn color="warning" class="mt-2" @click="resetValidation" :disabled="isLoading">
        Réinitialiser les validations
      </v-btn>
    </v-form>
    <v-alert v-if="isLoading" icon="mdi-timer-sand" prominent text type="info" class="mt-4">
      Veuillez patienter pendant la création de votre compte.
    </v-alert>
  </v-container>
</template>

<script>
  import { auth } from "@/firebase";
  export default {
    data() {
      return {
        valid: true,
        displayName: "",
        displayNameRules: [
          (v) => !!v || "Un nom d'utilisateur est requis",
          (v) => (v && v.length > 2) || "Le nom d'utilisateur doit contenir au moins 3 caractères",
        ],
        email: "",
        emailRules: [
          (v) => !!v || "Un courriel est requis",
          (v) => /.+@.+\..+/.test(v) || "Le courriel doit être valide",
        ],
        password: "",
        passwordRules: [(v) => !!v || "Required.", (v) => v.length >= 8 || "Un mimnimum de 8 caractère est requis"],
        passwordValidation: "",
        passwordValidationRules: [
          (v) => !!v || "Required.",
          (v) => v === this.password || "Le mot de passe ne correspond pas",
        ],
        showPassword: false,
        showPasswordValidation: false,
        checkbox: false,
        errorMessage: "",
        userMembershipInfo: { id: null, access: "aucun", endDate: null, note: "" },
        isLoading: false,
      };
    },
    methods: {
      reset() {
        this.$refs.form.reset();
      },
      resetValidation() {
        this.$refs.form.resetValidation();
      },
      passwordMatch() {
        return this.password === this.passwordValidation && this.password !== "" ? true : false;
      },
      async submitAction(evt) {
        this.isLoading = true;
        evt.preventDefault();

        const user = await auth.createUserWithEmailAndPassword(this.email, this.password).catch(function(error) {
          if (error.code === "auth/email-already-in-use") {
            this.isLoading = false;
            this.errorMessage = "Un utilisateur est déjà existant avec cette adresse courriel";
          }
        });

        if (user) {
          await auth.currentUser.updateProfile({
            displayName: this.displayName,
          });
          this.isLoading = false;
          this.$router.push({ name: "accueil" });
        }
      },
      initMessageError() {
        this.messageError = false;
      },
    },
  };
</script>

<style lang="scss" scoped></style>
