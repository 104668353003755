export default [
  "R1",
  //"R1/2",
  "R2",
  //"R2+",
  //"R2/2+",
  //"R2+/3",
  "R3",
  "R3+",
  //"R3/3+",
  //"R3+/4",
  "R4",
  "R4+",
  //"R4/4+",
  //"R4+/5.0",
  "R5",
  "R5+",
  "R5.0",
  //"R5.0/5.1",
  "R5.1",
  //"R5.1/5.2",
  "R5.2",
  //"R5.2/5.3",
  "R5.3",
];
