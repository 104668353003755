<template>
  <v-banner single-line>
    <v-icon slot="icon" color="warning" size="24">
      mdi-wallet-membership
    </v-icon>
    Vous n'avez actuellement aucun abonnement actif
    <template v-slot:actions>
      <v-btn color="primary" text @click="openCustomerPortal" v-if="hasSubscription">
        {{ isLoading ? "Chargement en cours..." : "Ouvrir mon portail d'abonnement" }}
      </v-btn>
      <v-btn color="primary" text :to="{ name: 'subscription' }" v-if="!hasSubscription">
        Devenir membre
      </v-btn>
    </template>
  </v-banner>
</template>

<script>
  import { app, auth, functions } from "@/firebase";
  export default {
    data() {
      return {
        isLoading: false,
        hasSubscription: false,
      };
    },
    async mounted() {
      this.isLoading = true;
      await this.customerHasSubscription();
      this.isLoading = false;
    },
    methods: {
      async customerHasSubscription() {
        const customerEmail = auth.currentUser.email;
        const customerHasSubscription = functions.httpsCallable("stripeIsCustomerHasSubscription");
        const { data } = await customerHasSubscription({ email: customerEmail });
        this.hasSubscription = data.hasSubscription;
      },
      async openCustomerPortal() {
        this.isLoading = true;
        const functions = app.functions("northamerica-northeast1");
        const functionRef = functions.httpsCallable("ext-firestore-stripe-payments-createPortalLink");
        const { data } = await functionRef({ return_url: window.location.origin });
        window.location.assign(data.url);
        this.isLoading = false;
      },
    },
  };
</script>

<style></style>
