<template>
  <v-container>
    <div>
      <v-row justify="space-around">
        <v-col v-for="photo in photos" :key="photo.url" cols="12" md="4" sm="4">
          <v-card height="100%" hover @click="showImage(photo)">
            <v-img
              :src="photo.url"
              class="white--text align-end"
              gradient="to top, rgba(0,0,0,.6), rgba(0,0,0,0)"
              max-height="300"
              position="center"
            >
              <v-card-title v-text="photo.description" class="mb-n3 ml-n2 caption"></v-card-title>
            </v-img>
          </v-card>
        </v-col>
      </v-row>
    </div>
    <v-overlay :absolute="absolute" :value="overlay">
      <div @click="overlay = false">
        <v-img :max-width="windowWidth" :src="currentPhoto.url">
          <v-card-title v-text="currentPhoto.description" class="mb-n3 ml-n2 caption"></v-card-title>
        </v-img>
      </div>
    </v-overlay>
  </v-container>
</template>

<script>
  import { SectionsService } from "@/store/modules/sections";
  export default {
    props: {
      section: {
        type: Object,
        default: function() {
          return {};
        },
      },
    },
    data() {
      return {
        photos: [],
        currentPhoto: {},
        overlay: false,
        absolute: false,
        windowHeight: window.innerHeight,
        windowWidth: window.innerWidth,
      };
    },
    async mounted() {
      if (this.section.image_info) {
        await this.getPhotos();
      }

      this.$nextTick(() => {
        window.addEventListener("resize", this.onResize);
      });
    },
    beforeDestroy() {
      window.removeEventListener("resize", this.onResize);
    },
    methods: {
      async getPhotos() {
        console.log("Section ID: " + this.section.id);
        const photosString = await SectionsService.actions.findSectionPhotos(this.section.id);
        this.photos = photosString ? JSON.parse(photosString.links) : [];
      },
      showImage(photo) {
        this.currentPhoto = photo;
        this.overlay = true;
      },
      onResize() {
        this.windowHeight = window.innerHeight;
        this.windowWidth = window.innerWidth;
      },
    },
  };
</script>

<style lang="scss" scoped></style>
