import Vue from "vue";
import Vuex from "vuex";
//import createPersistedState from "vuex-persistedstate";
//import * as Cookies from 'js-cookie';
import { vuexfireMutations } from "vuexfire";
import { RegionsService } from "@/store/modules/regions";
import { RiversService } from "@/store/modules/rivers";
import { SectionsService } from "@/store/modules/sections";
import { GaugesService } from "@/store/modules/gauges";
import levels from "@/store/modules/levels";

Vue.use(Vuex);

export const store = new Vuex.Store({
  mutations: {
    ...vuexfireMutations,
  },
  modules: {
    RegionsService,
    RiversService,
    SectionsService,
    GaugesService,
    levels,
  },
});
