<template>
  <div>
    <v-toolbar color="primary darken-1" dark>
      <v-toolbar-title class="subtitle-1">
        À propos de nous
      </v-toolbar-title>
    </v-toolbar>
    <v-container>
      <p>
        Ut elit anim cillum labore est non irure laborum est culpa consequat Lorem cupidatat. Incididunt in voluptate
        enim nostrud do excepteur irure voluptate exercitation fugiat proident amet officia dolore. Ut dolore laboris
        fugiat occaecat commodo non culpa sunt labore sit. Ullamco exercitation qui nulla consequat elit voluptate duis
        excepteur elit ex ad aute. Non duis pariatur aliquip amet laborum adipisicing reprehenderit excepteur occaecat
        cillum commodo occaecat pariatur amet. Sint aute laborum sunt tempor.
      </p>
      <p>
        Ipsum proident consectetur Lorem ullamco tempor veniam aliqua pariatur sint mollit mollit mollit. Consectetur
        Lorem qui magna ut aliquip. Labore aliquip irure consectetur enim laborum minim enim. Cupidatat magna occaecat
        esse commodo adipisicing quis.
        <router-link to="/qww-admin/insert-data"> .</router-link>
      </p>
    </v-container>
  </div>
</template>

<script>
  export default {};
</script>

<style lang="scss" scoped></style>
