<template>
  <v-sheet height="77.5vh" elevation="8">
    <gmaps-map ref="sectionMaps" :options="mapOptions">
      <gmaps-marker
        v-for="(marker, markerIndex) in markers"
        :key="`marker-${markerIndex}`"
        :options="marker.options"
        @click="openGoogleMap(marker)"
      />
      <gmaps-polyline :editable="editable" :path="sectionPath" stroke-color="dodgerblue" stroke-weight="5" />
    </gmaps-map>
  </v-sheet>
</template>

<script>
  import { SectionsService } from "@/store/modules/sections";
  import { gmapsMap, gmapsMarker, gmapsPolyline } from "x5-gmaps";
  import { GmapsService } from "@/services/Gmaps.service";
  export default {
    components: { gmapsMap, gmapsMarker, gmapsPolyline },
    props: {
      section: {
        type: Object,
        default: function() {
          return {};
        },
      },
    },
    data() {
      return {
        gmaps_key: process.env.VUE_APP_GOOGLE_MAPS_KEY,
        mapOptions: {
          mapTypeId: "terrain",
          center: GmapsService.BASE_CENTER_POSITION,
          zoom: 12,
        },
        markers: [],
        sectionPath: [],
        editable: false,
      };
    },
    async created() {
      await this.getDirections();
    },
    methods: {
      openGoogleMap(marker) {
        const urlSuffix = marker.options.position.lat + "," + marker.options.position.lng;
        const zoom = ",16z";

        window.open("https://www.google.com/maps/dir//" + urlSuffix + "/@" + urlSuffix + zoom, "_blank");
      },
      markerActions() {
        console.log("Actions!!!");
      },
      async getDirections() {
        this.directions = await SectionsService.actions.findSectionsDirections(this.section.id);
        this.setMarkers();
        this.setSectionPath();
      },
      setSectionPath() {
        this.sectionPath = [];
        if (this.directions.sectionPath) {
          if (this.directions.sectionPath.length > 0) this.sectionPath = JSON.parse(this.directions.sectionPath);
        }
      },
      setMarkers() {
        this.markers = [];

        if (!this.directions.markers) {
          this.getOldMarkers();
        } else if (this.directions.markers.length == 0) {
          this.getOldMarkers();
        } else {
          this.markers = JSON.parse(this.directions.markers);
        }

        this.getSVGsLabelOrigin();
        this.setCenterPosition();
      },
      setCenterPosition() {
        if (this.markers.length > 0) {
          this.mapOptions.center = GmapsService.averageGeolocation(this.markers);
        } else {
          this.mapOptions.center = GmapsService.BASE_CENTER_POSITION;
        }
      },
      getOldMarkers() {
        if (GmapsService.getLatLngFromStringLatLng(this.directions.takeout))
          this.markers.push({
            type: "putin",
            options: {
              position: GmapsService.getLatLngFromStringLatLng(this.directions.takeout),
              label: GmapsService.LABEL_PUTIN,
              icon: GmapsService.SVG_PUTIN,
            },
          });

        if (this.directions.putin.length > 0)
          if (GmapsService.getLatLngFromStringLatLng(this.directions.putin))
            this.markers.push({
              type: "takeout",
              options: {
                position: GmapsService.getLatLngFromStringLatLng(this.directions.putin),
                label: GmapsService.LABEL_TAKEOUT,
                icon: GmapsService.SVG_TAKEOUT,
              },
            });
      },
      getSVGsLabelOrigin() {
        var point = new window.google.maps.Point(12, 30);
        var svgPutin = GmapsService.SVG_PUTIN;
        var svgTakeout = GmapsService.SVG_TAKEOUT;
        svgPutin.labelOrigin = point;
        svgTakeout.labelOrigin = point;

        return { putin: svgPutin, takeout: svgTakeout };
      },
    },
  };
</script>

<style lang="scss" scoped></style>
