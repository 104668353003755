export const RiversService = {
  getClassLevels() {
    var classes = [
      { class_level: "R2" },
      { class_level: "R3" },
      { class_level: "R3+" },
      { class_level: "R4" },
      { class_level: "R4+" },
      { class_level: "R5" },
      { class_level: "R5+" },
    ];
    return classes;
  },
  getLastUpdate(timestamp_to_format) {
    var now = new Date();
    var last_update = "";
    if (timestamp_to_format) timestamp_to_format = timestamp_to_format.replace(/-/g, "/");
    var seconds = Math.floor((now - new Date(timestamp_to_format)) / 1000);
    var minutes = Math.floor(seconds / 60);
    var hours = Math.floor(minutes / 60);
    var days = Math.floor(hours / 24);

    hours = hours - days * 24;
    minutes = minutes - days * 24 * 60 - hours * 60;
    seconds = seconds - days * 24 * 60 * 60 - hours * 60 * 60 - minutes * 60;

    if (days < 2) {
      if (days > 0) {
        last_update = days + "j ";
      }

      if (hours > 0) {
        last_update += hours + "h";
      }

      if (minutes > 0) {
        if (hours > 0 && minutes < 10) last_update += "0";
        last_update += minutes;
        if (hours == 0) last_update += " min";
      }
    }

    return last_update;
  },
  getTotalRapids(obj) {
    var total_rapids = 0;

    if (!isNaN(parseInt(obj.rapidClassR2andLess))) {
      total_rapids += parseInt(obj.rapidClassR2andLess);
    }
    if (!isNaN(parseInt(obj.rapidClassR3))) {
      total_rapids += parseInt(obj.rapidClassR3);
    }
    if (!isNaN(parseInt(obj.rapidClassR4))) {
      total_rapids += parseInt(obj.rapidClassR4);
    }
    if (!isNaN(parseInt(obj.rapidClassR5))) {
      total_rapids += parseInt(obj.rapidClassR5);
    }
    if (!isNaN(parseInt(obj.rapidClassR5plus))) {
      total_rapids += parseInt(obj.rapidClassR5plus);
    }

    return total_rapids;
  },
  getSectionIconColor(isActive) {
    if (isActive) {
      return "blue";
    }

    return "grey lighten-2";
  },
};
