<template>
  <div>
    <v-fab-transition v-if="isModification">
      <v-btn v-hotkey="'ctrl+s'" color="green" fab dark fixed bottom right @click="save">
        <v-icon large>mdi-content-save-outline</v-icon>
      </v-btn>
    </v-fab-transition>
    <v-snackbar v-model="isSaved" color="success">
      La section a été sauvegardée avec succès.

      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="isSaved = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
    <v-form ref="sectionGeneral" v-model="valid" @change="handleChange">
      <v-container>
        <v-row>
          <v-col>
            <v-alert v-if="errorMessage" dense text border="left" type="error" elevation="2">
              {{ errorMessage }}
            </v-alert>
            <v-alert v-if="isModification" dense text border="left" type="warning" elevation="2">
              Attention des modifications semblent en cours dans cet onglet. Veuillez vous assurer de sauvegarder avant
              de changer de section ou d'onglet. (Appuyer sur CTRL+S pour sauvegarder)
            </v-alert>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-switch v-model="section.published" label="Publiée"></v-switch>
            <v-switch v-model="section.free_access" label="Gratuite" class="ml-6"></v-switch>
          </v-col>
          <v-col align="right">
            <v-btn v-if="!isDeleteSection" icon color="error" @click="deleteSectionConfirmation()">
              <v-icon large>mdi-trash-can</v-icon>
            </v-btn>
            <v-btn v-if="isDeleteSection" text color="error" @click="deleteSection()">
              Oui, je veux supprimer définitivement cette section
            </v-btn>
            <v-btn v-if="isDeleteSection" text @click="deleteSectionCancel()">
              Non, annuler l'action
            </v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6">
            <v-card>
              <v-card-text>
                <v-combobox
                  v-model="section.region"
                  :items="regions"
                  item-text="name"
                  item-value="name"
                  label="Région"
                  :return-object="false"
                  required
                ></v-combobox>
                <v-combobox
                  v-model="section.river"
                  :items="rivers"
                  item-text="name"
                  item-value="'name'"
                  label="Nom de la rivière"
                  :return-object="false"
                  required
                ></v-combobox>
                <v-text-field label="Nom de la section" v-model="section.name"></v-text-field>
                <v-autocomplete
                  v-model="section.type"
                  :items="sectionTypes"
                  label="Catégorie de rivière"
                  required
                ></v-autocomplete>
                <v-row>
                  <v-col>
                    <v-text-field label="Longueur de la section (km)" v-model="section.length"></v-text-field>
                  </v-col>
                  <v-col>
                    <v-text-field label="Nombre de rapides" v-model="section.rapidsAmount" type="number"></v-text-field>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="6">
            <v-card>
              <v-card-text>
                <v-row>
                  <v-col>
                    <div class="d-inline-block">
                      <v-autocomplete
                        v-model="section.class_level"
                        :items="cotations"
                        label="Cotation"
                      ></v-autocomplete>
                    </div>
                    <div class="d-inline-block">
                      <span class="black--text font-weight-bold">{{ section.class_level }}</span>
                    </div>
                  </v-col>
                  <v-col>
                    <div class="d-inline-block">
                      <v-autocomplete
                        v-model="section.class_level_max"
                        :items="cotations"
                        label="Cotation max"
                      ></v-autocomplete>
                    </div>
                    <div class="d-inline-block">
                      <span class="black--text font-weight-bold">{{ section.class_level_max }}</span>
                    </div>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col>
                    <v-text-field label="Débit minimum" type="number" v-model="section.min_sug_level"></v-text-field>
                  </v-col>
                  <v-col>
                    <v-text-field label="Débit maximum" type="number" v-model="section.max_sug_level"></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-text-field label="Gradient" type="number" v-model="section.gradient"></v-text-field>
                  </v-col>
                  <v-col>
                    <v-text-field label="Navette (km)" type="number" v-model="section.shuttle"></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-text-field label="Portages" v-model="section.portage"></v-text-field>
                  </v-col>
                  <v-col></v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-card>
              <v-card-title>Description</v-card-title>
              <v-card-text>
                <tiptap-vuetify v-model="section.description" :extensions="extensions" />
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-form>
  </div>
</template>

<script>
  import {
    TiptapVuetify,
    Heading,
    Bold,
    Italic,
    Strike,
    Underline,
    Code,
    Paragraph,
    BulletList,
    OrderedList,
    ListItem,
    Link,
    Blockquote,
    HardBreak,
    HorizontalRule,
    History,
  } from "tiptap-vuetify";
  import { RiversService } from "@/services/Rivers.service";
  import { SectionsService } from "@/store/modules/sections";
  import cotations from "@/store/db/cotations.db";
  import sectionTypes from "@/store/db/sectionTypes.db";
  export default {
    components: { TiptapVuetify },
    props: {
      section: {
        type: Object,
        default: function() {
          return {};
        },
      },
      reloadData: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        isDeleteSection: false,
        isModification: false,
        isSaved: false,
        errorMessage: "",
        valid: true,
        cotations: cotations,
        sectionTypes: sectionTypes,
        textFieldRules: [
          (value) => !!value || "Required.",
          (value) => (value && value.length >= 3) || "Min 3 characters",
        ],
        extensions: [
          History,
          Blockquote,
          Link,
          Underline,
          Strike,
          Italic,
          ListItem,
          BulletList,
          OrderedList,
          [
            Heading,
            {
              options: {
                levels: [1, 2, 3],
              },
            },
          ],
          Bold,
          Code,
          HorizontalRule,
          Paragraph,
          HardBreak,
        ],
      };
    },
    async created() {
      await this.getRegions();
      await this.getRivers();
    },
    methods: {
      async getRegions() {
        await this.$store.dispatch("bindRegions");
      },
      async getRivers() {
        await this.$store.dispatch("bindRivers");
      },
      getTotalRapids(obj) {
        return RiversService.getTotalRapids(obj);
      },
      handleChange() {
        this.isSaved = false;
        this.errorMessage = "";
        this.isModification = true;
      },
      async save() {
        if (this.valid) {
          const riverExist = this.lookIfRiverExist();
          console.log("riverExist is " + riverExist); // TODO add river (create alias)
          const regionExist = this.lookIfRegionExist();
          console.log("regionExist is " + regionExist); // TODO add région (create alias)

          this.updateSection();
        } else {
          this.errorMessage = "Certains champs obligatoires ne répondent pas aux critères attendus, veuillez vérifier.";
        }
      },
      updateSection() {
        SectionsService.actions
          .updateSection(this.section)
          .then(() => {
            this.isModification = false;
            this.isSaved = true;
            this.successMessage;
          })
          .catch((error) => {
            console.log(error);
            this.errorMessage =
              "Une erreur s'est produite lors de la sauvegarde de la section, vérifiez les résultats et recommencez.";
          });
      },
      deleteSectionConfirmation() {
        this.isDeleteSection = true;
      },
      deleteSectionCancel() {
        this.isDeleteSection = false;
      },
      deleteSection() {
        SectionsService.actions
          .deleteSection(this.section)
          .then(() => {
            this.deleteSectionCancel();
          })
          .catch((error) => {
            console.log(error);
            this.errorMessage =
              "Une erreur s'est produite lors de la suppression de la section, vérifiez les résultats et recommencez.";
          });
      },
      lookIfRiverExist() {
        const rivers = this.$store.getters.rivers;
        var exist = false;
        rivers.forEach((river) => {
          if (river.name.toLowerCase() === this.section.river.toLowerCase()) {
            exist = true;
          }
        });
        return exist;
      },
      lookIfRegionExist() {
        const regions = this.$store.getters.regions;
        var exist = false;
        regions.forEach((region) => {
          if (region.name.toLowerCase() === this.section.region.toLowerCase()) {
            exist = true;
          }
        });
        return exist;
      },
    },
    computed: {
      regions() {
        return this.$store.getters.regions;
      },
      rivers() {
        return this.$store.getters.rivers;
      },
    },
    watch: {
      section: {
        deep: true,
        handler(oldValue, newValue) {
          this.isSaved = false;
          this.errorMessage = "";
          if (oldValue.id !== newValue.id) {
            this.isModification = false;
          } else {
            this.isModification = true;
          }
        },
      },
    },
  };
</script>

<style lang="scss" scoped>
  .v-input--switch {
    display: inline-block;
  }
  .nowrap-overflow {
    flex-wrap: nowrap;
    overflow-x: auto;
  }
</style>
