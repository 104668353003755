<template>
  <div>
    <v-data-table
      :items="sections"
      :headers="getHeaders()"
      :loading="loadingSection"
      :search="search"
      disable-pagination
      hide-default-footer
      hide-header
      group-by="region"
      mobile-breakpoint="0"
    >
      <template v-slot:[`group.header`]="{ group, headers, toggle, isOpen }">
        <td :colspan="headers.length" @click="toggle" id="region" class="primary lighten-4">
          <v-btn small icon :ref="group" :data-open="isOpen">
            <v-icon v-if="isOpen">mdi-chevron-up</v-icon>
            <v-icon v-else>mdi-chevron-down</v-icon>
          </v-btn>
          <strong>{{ group }}</strong>
        </td>
      </template>

      <template v-slot:[`item`]="{ item }">
        <tr @click="showRiver(item)">
          <td :class="getBorderColor(item)" valign="top">
            <strong class="grey--text text--darken-3">{{ item.river }} | </strong>
            <span>
              <strong class="grey--text text--darken-3 class-text">{{ item.class_level }}</strong>
              <strong v-if="item.class_level_max" class="grey--text class-text"> ({{ item.class_level_max }}) </strong>
            </span>
            <br />
            <span class="font-weight-medium blue--text text--darken-2">{{ item.name }}</span> <br />
            <span class="font-weight-bold grey--text text--darken-1 number-rapids-text">
              <span v-if="item.length">{{ item.length }}km</span>
              <span v-if="item.length && item.rapidsAmount"> - </span>
              <span v-if="item.rapidsAmount">
                {{ item.rapidsAmount }}
                rapide<span v-if="item.rapidsAmount > 1">s</span>
              </span>
            </span>
            <br />
            <v-item-group>
              <v-icon :color="getIconColor(item.image_info)" title="Images disponibles" small>
                mdi-image-multiple
              </v-icon>
              <v-icon :color="getIconColor(item.video_info)" title="Vidéos disponibles" small>mdi-file-video</v-icon>
              <v-icon :color="getIconColor(item.topo_info)" title="Cartes topo disponibles" small>
                mdi-map-legend
              </v-icon>
              <v-icon :color="getIconColor(item.direction_info)" title="Directions disponibles" small>
                mdi-google-maps
              </v-icon>
            </v-item-group>
          </td>
          <td class="pl-0 pr-1 text-left" valign="top">
            <div v-if="isMember || item.free_access === true">
              <strong :class="getColor(item)">{{
                item.current_level === "NaN" || item.current_level == 0 ? "" : item.current_level
              }}</strong>
              <br />
              <div class="d-inline-flex">
                <span v-if="item.max_sug_level > 0" class="red--text">
                  {{ getFormattedMinOrMax(item.min_sug_level) }}
                </span>
                <span v-if="item.max_sug_level > 0">&nbsp;|&nbsp;</span>
                <span v-if="item.min_sug_level > 0" class="blue--text">
                  {{ getFormattedMinOrMax(item.max_sug_level) }}
                </span>
              </div>
              <br />
              <span class="font-weight-bold current-trend-text d-inline-flex">
                {{
                  item.current_trend === "NaN" || (item.current_trend == 0 && item.current_level == 0)
                    ? ""
                    : Math.abs(item.current_trend) + "/h"
                }}
                <v-icon v-if="Math.sign(item.current_trend) == 1" x-small color="green darken-2">
                  mdi-arrow-up
                </v-icon>
                <v-icon v-if="Math.sign(item.current_trend) == -1" x-small color="red">
                  mdi-arrow-down
                </v-icon>
              </span>
              <br />
              <span v-if="getLastUpdate(item.level_last_update)" class="font-weight-bold last-update-text">
                il y a {{ getLastUpdate(item.level_last_update) }}
              </span>
              <span v-if="item.current_trend > 0 && !getLastUpdate(item.level_last_update)">
                <v-icon
                  color="yellow darken-3"
                  :title="'Dernière mise à jour le ' + getLastUpdateDayAndMonth(item.level_last_update)"
                  small
                >
                  mdi-alert
                </v-icon>
              </span>
            </div>
            <div v-else>
              <strong id="blur">0.00</strong> <br />
              <v-btn text color="primary" small to="/nouvel-utilisateur">Devenez membre</v-btn> <br />
              <span id="blur">42m</span>
            </div>
          </td>
        </tr>
      </template>
    </v-data-table>
  </div>
</template>

<script>
  import { RiversService } from "@/services/Rivers.service";
  import { StringService } from "@/services/String.service";

  export default {
    props: {
      sections: {
        type: Array,
        default() {
          return [];
        },
      },
      loadingSection: {
        type: Boolean,
        default: false,
      },
      isMember: {
        type: Boolean,
        default: false,
      },
      search: {
        type: String,
        default: "",
      },
    },
    data() {
      return {
        classLevelTile: "Poire",
        classLevelWidth: "",
        classLevelFixed: false,
        minMaxWidth: "",
        minMaxFixed: false,
        headers: [
          { text: "Section", value: "name", align: " d-none" },
          {
            text: "Rivière / Section info",
            value: "river",
            sortable: false,
            class: "primary lighten-4",
          },
          {
            text: "Région",
            value: "region",
            sortable: false,
            class: "primary lighten-4",
          },
          {
            text: "Débit info",
            value: "current_data",
            sortable: false,
            class: "primary lighten-4 pl-0",
          },
        ],
        headersMobile: [
          { text: "Section", value: "name", align: " d-none" },
          {
            text: "Rivière / Section",
            value: "river",
            sortable: false,
            class: "primary lighten-4",
          },
          {
            text: "Région",
            value: "region",
            sortable: false,
            class: "primary lighten-4",
          },
          {
            text: "Débit info",
            value: "current_data",
            sortable: false,
            class: "primary lighten-4 text-right pl-0",
            width: "70px",
            fixed: true,
          },
        ],
        level_unknown: "unknown",
        level_low: "low",
        level_recommended: "recommended",
        level_high: "high",
      };
    },
    methods: {
      getHeaders() {
        switch (this.$vuetify.breakpoint.name) {
          case "xs":
            return this.headersMobile;
          case "sm":
            return this.headers;
          case "md":
            return this.headers;
          case "lg":
            return this.headers;
          case "xl":
            return this.headers;
        }
      },
      getColor(obj) {
        if (obj.level_status === this.level_recommended) {
          return "green--text";
        }
        if (obj.level_status === this.level_high) {
          return "blue--text";
        }
        if (obj.level_status === this.level_low) {
          return "red--text";
        }
      },
      getBorderColor(obj) {
        const padding = " pl-2 pr-2";
        if (this.isMember || obj.free_access) {
          if (obj.level_status === this.level_recommended) {
            return "border-recommended" + padding;
          }
          if (obj.level_status === this.level_high) {
            return "border-too-high" + padding;
          }
          if (obj.level_status === this.level_low) {
            return "border-too-low" + padding;
          }
          if (obj.level_status === this.level_unknown) {
            return "border-unknown" + padding;
          }
        }
        return "border-unknown" + padding;
      },
      getIconColor(isActive) {
        return RiversService.getSectionIconColor(isActive);
      },
      getLastUpdate(timestamp_to_format) {
        return RiversService.getLastUpdate(timestamp_to_format);
      },
      getLastUpdateDayAndMonth(timestamp_to_format) {
        return StringService.getLastUpdateDayAndMonth(timestamp_to_format);
      },
      getFormattedMinOrMax(string) {
        return StringService.numberFormatter(string, 2);
      },
      showRiver(row) {
        var slug = row.alias;
        this.isMember || row.free_access === true
          ? this.$router.push({ name: "riviere", params: { slug: slug, river: row } })
          : "";
      },
    },
  };
</script>

<style lang="scss" scoped>
  header {
    display: flex;
    align-items: center;
  }

  .class-text {
    font-size: 0.85rem;
  }

  .flow-column {
    align-content: right;
  }

  .number-rapids-text {
    font-size: 0.75rem;
  }

  .rapids-class-text {
    font-size: 0.68rem;
  }

  .number-rapids-width {
    width: 70px;
  }

  .current-trend-text {
    font-size: 0.75rem;
  }

  .last-update-text {
    font-size: 0.6rem;
  }

  .margin-top {
    margin-top: 2rem;
  }

  .collapsed > .when-open,
  .not-collapsed > .when-closed {
    display: none;
  }

  .bold {
    font-weight: bold;
  }

  .border-too-high {
    border-left: 8px solid #2196f3;
  }

  .border-recommended {
    border-left: 8px solid #4caf50;
  }

  .border-too-low {
    border-left: 8px solid #f44336;
  }

  .border-unknown {
    border-left: 8px solid lightgrey;
  }

  #blur {
    filter: blur(3px);
    -webkit-filter: blur(3px);
  }
</style>
