import { render, staticRenderFns } from "./InsertData.vue?vue&type=template&id=855c080c&scoped=true"
import script from "./InsertData.vue?vue&type=script&lang=js"
export * from "./InsertData.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "855c080c",
  null
  
)

export default component.exports