import { render, staticRenderFns } from "./Directions.vue?vue&type=template&id=a8fb4d34&scoped=true"
import script from "./Directions.vue?vue&type=script&lang=js"
export * from "./Directions.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a8fb4d34",
  null
  
)

export default component.exports