<template>
  <v-container>
    <v-form ref="form" v-model="valid" @submit="submitAction">
      <v-col cols="12" sm="6">
        <h1 class="text-h5 text-md-h3 text-lg-h2 text-xl-h2 text-truncate">
          Connexion
        </h1>
        <v-text-field v-model="email" :rules="emailRules" label="E-mail" required></v-text-field>
        <v-text-field
          v-model="password"
          :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
          :rules="passwordRules"
          label="Mot de passe"
          :type="showPassword ? 'text' : 'password'"
          name="password"
          hint="At least 8 characters"
          class="input-group--focused"
          @click:append="showPassword = !showPassword"
        ></v-text-field>
        <v-checkbox
          v-model="checkbox"
          :rules="[(v) => !!v || 'Vous devez cocher cette case pour continuer']"
          label="Je ne suis pas un robot"
          required
        ></v-checkbox>
      </v-col>

      <v-alert border="left" color="red lighten-1" dark v-if="errorMessage">
        {{ errorMessage }}
      </v-alert>

      <v-btn :disabled="!valid" color="success" class="mr-4 mb-7" type="submit">
        Connexion
      </v-btn>
      <div>
        <p>
          Vous n'avez pas de compte?
          <v-btn text color="primary" to="/nouvel-utilisateur">Créez votre compte ici</v-btn>
        </p>
        <p>
          Mot de passe oublié?
          <v-btn text color="primary" to="/reinitialiser-mot-de-passe">Réinitialiser votre mot de passe ici</v-btn>
        </p>
      </div>
    </v-form>
  </v-container>
</template>

<script>
  import { auth } from "@/firebase";
  export default {
    data() {
      return {
        valid: true,
        email: "",
        emailRules: [
          (v) => !!v || "Un courriel est requis",
          (v) => /.+@.+\..+/.test(v) || "Le courriel doit être valide",
        ],
        password: "",
        passwordRules: [(value) => !!value || "Required."],
        showPassword: false,
        checkbox: false,
        errorMessage: false,
      };
    },
    methods: {
      async submitAction(evt) {
        evt.preventDefault();

        try {
          await auth.signInWithEmailAndPassword(this.email, this.password);
          this.$router.push({ name: "accueil" });
        } catch (error) {
          this.errorMessage = "Email ou mot de passe erroné";
          console.log("error", error);
        }
      },
      initErrorMessage() {
        this.errorMessage = false;
      },
    },
  };
</script>

<style lang="scss" scoped></style>
