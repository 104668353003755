<template>
  <div>
    <v-toolbar color="primary darken-1" dark>
      <v-toolbar-title class="subtitle-1"> Liste des utilisateurs <small>(Administration)</small> </v-toolbar-title>
    </v-toolbar>

    <v-card>
      <v-btn class="" dark absolute top right fab color="success" @click="$router.push({ name: 'admin-user-add' })">
        <v-icon dark>
          mdi-plus
        </v-icon>
      </v-btn>
      <v-card-title>
        Utilisateurs
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Rechercher"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>
      <v-data-table :headers="headers" :items="users" :search="search">
        <template v-slot:item.actions="{ item }">
          <v-icon small class="mr-2" @click="editUser(item)"> mdi-pencil </v-icon>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
  import { /*auth,*/ functions } from "@/firebase";

  export default {
    data() {
      return {
        search: "",
        headers: [
          {
            text: "Nom",
            align: "start",
            value: "displayName",
          },
          { text: "Email", value: "email" },
          { text: "Créé le", value: "metadata.creationTime" },
          { text: "Dernière connexion", value: "metadata.lastSignInTime" },
          { text: "ID", value: "uid" },
          { text: "Action", value: "actions" },
        ],
        users: [],
      };
    },
    async created() {
      await this.getAllUsers();
    },
    methods: {
      async getAllUsers() {
        var getAllUsersFromFirebase = functions.httpsCallable("getAllUsersFromFirebase");
        const users = await getAllUsersFromFirebase()
          .then((result) => {
            return result.data;
          })
          .catch((error) => {
            console.log(error);
            return {};
          });
        this.users = users;
      },
      editUser(user) {
        var slug = user.uid;
        this.$router.push({ name: "admin-user-edit", params: { slug: slug, user: user } });
      },
    },
  };
</script>

<style></style>
